import { mkResponseSchema, GoonoResponse, GoonoPendingTasks } from "./common";
import { UserObject, UserObjectSchema } from "../object/user";
import T from "@redwit-commons/utils/typecheck";
import {
  OAdminUserModify,
  OAdminUserModifySchema,
  validateOAdminUserModify,
} from "./admin";

export type OLogin = GoonoResponse<{
  needRegister: boolean;
  token: string;
  errorMessage?: string;
  platform?: string;
}>;

export type OLoginWithEmail = GoonoResponse<{
  needRegister: boolean; // no error, no user -> true, if error -> false
  token: string; // given only when email verified & valid password, else empty string
  errorMessage?: string;
  failedCount?: number;
}>;

export enum emailLoginErrorType {
  INVALID_PASSWORD = "invalid_password",
  NOT_VERIFIED = "not_verified",
  SEND_MAIL_AGAIN = "send_mail_again", // TODO: remove this
  BLOCKED_30_MINS = "blocked_30_mins",
}

export enum snsLoginErrorType {
  INCORRECT_PLATFORM = "incorrect platform",
}

export const OLoginSchema = mkResponseSchema(
  T.object()
    .addField("needRegister", T.boolean())
    .addField("token", T.string())
    .addField("errorMessage", T.string(), false)
    .addField("platform", T.string(), false)
);

export const OLoginWithEmailSchema = mkResponseSchema(
  T.object()
    .addField("needRegister", T.boolean())
    .addField("token", T.string())
    .addField("errorMessage", T.string(), false)
    .addField("failedCount", T.number(), false)
);

export type OCheckRegistered = GoonoResponse<{
  available: boolean;
  message?: string;
  platform?: string;
}>;

export const OCheckRegisteredSchema = mkResponseSchema(
  T.object()
    .addField("available", T.boolean())
    .addField("message", T.string(), false)
    .addField("platform", T.string(), false)
);

export const validateOCheckRegistered = T.mkValidator<OCheckRegistered>(
  OCheckRegisteredSchema
);

export const validateOLogin = T.mkValidator<OLogin>(OLoginSchema);

export const validateOLoginWithEmail = T.mkValidator<OLoginWithEmail>(
  OLoginWithEmailSchema
);

export type OEmailVerify = GoonoResponse<{ result: string }>;

export const OEmailVerifySchema = mkResponseSchema(
  T.object().addField("result", T.string())
);

export type ORegister = GoonoResponse<{ token: string }> &
  GoonoPendingTasks<{
    hlf_create_user: string;
    hlf_create_project: string;
    hlf_share_project?: string;
  }>;

export type ORegisterWithEmail = GoonoResponse<{ token: string }> &
  GoonoPendingTasks<{
    hlf_create_user?: string;
    hlf_create_project?: string;
    hlf_share_project?: string;
    email_verification: string;
  }>;

export const ORegisterSchema = mkResponseSchema(
  T.object().addField("token", T.string()),
  T.object()
    .addField("hlf_create_user", T.string())
    .addField("hlf_create_project", T.string())
    .addField("hlf_share_project", T.string(), false)
);

export const ORegisterWithEmailSchema = mkResponseSchema(
  T.object().addField("token", T.string()),
  T.object()
    .addField("hlf_create_user", T.string(), false)
    .addField("hlf_create_project", T.string(), false)
    .addField("hlf_share_project", T.string(), false)
    .addField("email_verification", T.string())
);

export type OProfile = GoonoResponse<UserObject>;

export const OProfileSchema = mkResponseSchema(UserObjectSchema);

export const validateORegister = T.mkValidator<ORegister>(ORegisterSchema);

export const validateORegisterWithEmail = T.mkValidator<ORegisterWithEmail>(
  ORegisterWithEmailSchema
);

export const validateOProfile = T.mkValidator<OProfile>(OProfileSchema);

export const validateOProfileUpdate = T.mkValidator<OProfile>(OProfileSchema);

export type OProfileUpdate = GoonoResponse<UserObject>;

export const OProfileUpdateSchema = mkResponseSchema(UserObjectSchema);

export type ODeleteAccount = GoonoResponse<UserObject>;
export const ODeleteAccountSchema = mkResponseSchema(UserObjectSchema);

export type OAuthenticationPW = GoonoResponse<Record<string, never>> &
  GoonoPendingTasks<{
    email_verification: string;
  }>;

export const OAuthenticationPWSchema = mkResponseSchema(
  T.object(),
  T.object().addField("email_verification", T.string())
);
export const validateOAuthenticationPW = T.mkValidator<OAuthenticationPW>(
  OAuthenticationPWSchema
);

export type OUpdatePW = GoonoResponse<{
  updated: boolean;
  errMSG?: string;
}>;
export const OUpdatePWSchema = mkResponseSchema(
  T.object()
    .addField("updated", T.boolean())
    .addField("errMSG", T.string(), false)
);
export const validateOUpdatePW = T.mkValidator<OUpdatePW>(OUpdatePWSchema);

export type OUserModify = OAdminUserModify;
export const OUserModifySchema = OAdminUserModifySchema;
export const validateOUserModify = validateOAdminUserModify;
