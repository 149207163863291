export default {
  ko: {
    new_service_linkage: {
      title: "서비스 연동",
      sub_title: "서비스 연동 현황",
      sub_title_guid: (name?: string) =>
        `${name} (님)이 연동하신 Repository 목록입니다.`,
      sub_title_guid_empty: (name?: string) =>
        `${name} (님)이 연동하신 Repository 목록이 없습니다.`,
      linkage: "Repository 연동",
      desc: {
        title: "Github Repository 연동",
        p1: "연동을 원하는 연구노트에서 오른쪽 상단",
        p1_2: "클릭!",
        p2: "매일 밤 12시, 하루 동안의 Commit history, Comment , Issue 자동 추가",
        p2_highlight: "매일 밤 12시,",
        p3: "자동 추가 자료 시점인증 진행",
        p3_highlight: "시점인증 진행",
      },
      empty: "연동된 연구노트, 연구폴더가 없습니다.",
      unlinkage: "연동 해제",
      guide_btn: "서비스 연동 사용설명서",
      free_plan_desc: "서비스 연동은 유료플랜이 있는 유저를 위한 기능입니다.",
    },
    github_linkage: {
      title: "Repository 연동",
      step1: "연구노트",
      step1_guid: "Repository를 연동할 연구노트를 선택해주세요.",
      note_search: "연구노트 이름으로 검색해 주세요",
      all: "전체",
      owner_writer: "소유권한 / 작성권한",
      step2: "연구폴더",
      step2_guide: "선택하지 않을 시 연구노트 홈으로 연동됩니다.",
      note_home: "연구노트 홈",
    },
    landing_help: {
      title: "자주 묻는 질문",
      subtitle_1: "구노 도입 전 이런 부분이 궁금하진 않으신가요?",
      subtitle_2:
        "그밖에 궁금한 사항이 있으시다면 구노팀이 신속하게 안내해드립니다.",
      contact_btn: "문의하기",
      qna: {
        q_1: {
          q: "과제 선정된 지 좀 지났는데, 지금부터 연구노트 작성해도 괜찮을까요?",
          a_1: "과제 종료 이전이라면 언제든 연구파일 업로드가 가능합니다.",
          a_2: "기존에 작성하셨던 기록물들은 이미지나,",
          a_3: "PDF, DOCS, EXLS, PPTX ",
          a_4: "형태의 파일로 ",
          a_5: "업로드 할 수 있으며,",
          a_6: "작성 날짜를 별도",
          a_7: "로 설정할 수 있습니다.",
          a_8: "(다만, 시점인증은 업로드 시의 시점으로 인증이 되며, 과제 종료 기간 이전이라면 문제가 되지 않습니다.)",
          a_9: "또한 업로드와 동시에 법령에서 요구하는 조건(작성자 서명, 시점 인증 등)이 자동으로 갖춰져 연구노트로서 법적 효력을 갖습니다.",
          a_10: "수기 연구노트를 작성했던 경우라면, 구노를 통해 업로드 하시고, 혹시 모를 상황에 대비해 기존 수기 연구기록물은 별도로 보관해두는 것을 적극 권장 드립니다.",
        },
        q_2: {
          q: "R&D과제 진행 중인데 연구노트로 인정이 되나요?",
          a_1: "구노는",
          a_2: "중소벤처기업부에서 진행하는 ",
          a_3: "전자연구노트 서비스 ",
          a_4: "공식 제공 기업으로 ",
          a_5: "선정된 서비스",
          a_6: "입니다.",
          a_7: "중기부 과제 뿐만 아니라,",
          a_8: "구노는 하단 기재된 연구노트 법령을 ",
          a_9: "만족하기 때문에 다른 과제에서도 ",
          a_10: "증빙으로 사용하실 수 있습니다.",
          a_11: "구노를 활용하여 작성되는 모든 연구노트는 증빙자료로 인정되며, 정부 R&D 사업 및 특허출원, 기술이전, 지식재산권 보호 등 법적 자료로 다양한 분야에 활용 가능합니다.",
          a_12: "[법령요구 전자연구노트의 요건]",
          a_13: "기록자, 점검자의 서명인증 기능 (필요 시 연구기간의 장은 점검자의 서명기능을 제외가능)",
          a_14: "연구기록 입력일과 시간의 공인된 자동기록 기능",
          a_15: "기록물의 위·변조 확인 기능",
        },
        q_3: {
          q: "비용 처리는 어떻게 하나요?",
          a_1: `구노는 "연구비=과제비"로 처리가 가능합니다.`,
          a_2: "구노 사용으로 발생하는 비용은 직/간접비로 처리가 가능하여, 비용에 대한 부담을 덜어드립니다.",
          a_3: "연구활동비(직접비)",
          a_4: "연구장비재료비 - 전산처리 및 관리비(직접비)",
          a_5: "성과활용지원비 (간접비)지식재산권 출원ㆍ등록비 ",
          a_6: `해당 연도에 연구기관에서 수행하는 국가연구개발사업과
                관련된지식재산권의 출원ㆍ등록ㆍ유지 등에 필요한 모든 경비 또는
                기술가치평가 등 기술이전에 필요한 경비,국내외 표준등록 등
                표준화(인증포함) 활동에 필요한 경비, 연구노트 작성 및 관리에
                관한 자체 규정 제정ㆍ운영, 연구노트 교육ㆍ인식확산 활동 및
                연구노트 활성화 등과 관련된 경우`,
        },
        q_4: {
          q: "포스트잇이나, 화이트보드에 적은 기록도 정말 연구노트 인정이 되나요?",
          a_1: `이미지, 오피스 문서 파일 외에도 기록한 모든 자료를 구노를 통해 업로드 하면, 전자연구노트로 인정됩니다.`,
          a_2: "포스트잇이나 화이트보드에 적은",
          a_3: "기록은 물론이고",
          a_4: "태블릿PC를 통해 메모한 그림 등",
          a_5: "형식에 제한 없이 업로드하여 ",
          a_6: `전자연구노트로 활용할 수 있습니다.`,
        },
        q_5: {
          q: "과제 참여자가 5명인데 모두 가입해야 하나요?",
          a_1: `책임 연구자가 모든 연구노트를 작성하여 제출하는 것 자체는 문제가 되지 않습니다.`,
          a_2: `원칙상 연구노트는 과제에 참여하는 개별 멤버이 각각의 연구 활동을
          증빙하는 자료이기 때문에, 개별 연구자마다 가입하여 작성을 하는 것이
          가장 좋습니다.`,
          a_3: `책임 연구자 1명이 대표로 모든 연구노트를 업로드 하는 경우, 해당
            관리자 1인에 대해서만 과제 참여가 인정되는 것이기 때문에 추후
            연구자참여 관련 이슈가 발생할 수 있으며, 모든 연구자가 연구에
            참여했는지를 소명하기 어렵습니다.`,
          a_4: "하여,",
          a_5: "모든 연구 참여 인원에게 유료 플랜을 할당하여 ",
          a_6: `각각의 연구 작성물에 대한 활동을 증빙하는 것을 추천드립니다.`,
        },
        q_6: {
          q: "구노로 생성되는 연구노트는 과제 책임자 or 확인자의 서명란이 없는데 괜찮은가요?",
          a_1: `구노 전자연구노트에서의`,
          a_2: `확인자 서명은`,
          a_3: `제 3자로 시스템상 서버가 대신 처리`,
          a_4: ` 해줍니다.`,
          a_5: `해당 인증은 블록체인과 한국특허전략원과 한국전자인증의 승인을 받아
            적용되고 있습니다.`,
          a_6: `또한 과제 책임자의 정보를 개별적인 프로젝트 내에 추가할 수 있어
            선택적으로 활용할 수 있습니다.`,
        },
        q_7: {
          q: "외부 유출이나 해킹에 대해선 안심해도 되는건가요?",
          a_1: `구노는`,
          a_2: `프라이빗 블록체인 체계로 `,
          a_3: `모든 문서가 `,
          a_4: `암호화 되어 보관`,
          a_5: `되고 있으며,`,
          a_6: `구노 자체 서버를`,
          a_7: "구축하여 보다 강력하게 보호되고 있습니다.",
          a_8: `구노 자체 서버에는 연구노트의 해시값만 네트워크에 저장되는 2중
            보안이 적용되기 때문에 자사에서도 이용자 개개인이 업로드한 컨텐츠
            파일의 열람은 불가하니 자료 유출 및 해킹은 걱정하지 않으셔도 됩니다.`,
        },
        q_8: {
          q: "유료 플랜 기간이 종료되면 노트가 사라지나요?",
          a_1: `유료 플랜 기간동안 업로드한 게시물은`,
          a_2: `종료 후에도 안전하게 보관 및 유지됩니다.`,
          a_3: `다만, 종료 이후에는`,
          a_4: `유료 플랜의 `,
          a_5: `기능에 제한이 생겨`,
          a_6: `워터마크 생성, 폴더 생성,`,
          a_7: `폴더 공유, OCR 검색,`,
          a_8: "외부 서비스 연동 ",
          a_9: `등의 이용이 불가`,
          a_10: "합니다.",
        },
      },
    },
    loading_screen: {
      loading: "데이터를 불러오는 중...",
    },
    admin: {
      tabs: {
        insight: {
          title: "연구노트 현황",
          subTitle:
            "워크스페이스 멤버의 연구노트 작성 현황을 확인할 수 있습니다.",
          sort: {
            title: `연구파일 정렬`,
            sort_by_last_created: "생성일자 최신 순",
            sort_by_first_created: "생성일자 오래된 순",
            sort_by_ascending_name: "이름 오름차순",
            sort_by_descending_name: "이름 내림차순",
          },
        },
      },
    },
    workspace_setting: {
      screen_title: "워크스페이스 설정",
      tab: {
        overview: "개요",
        member: "멤버관리",
        security: "보안/접근",
      },

      current_plan: {
        title: "현재 사용중인 플랜",
        period: "플랜 유효기간",
        member: "플랜 연구원",
      },
      overview: {
        title: "워크스페이스 설정",
        leave_btn: "워크스페이스 나가기",
        leave_desc:
          "내가 소유한 연구노트 권한은 모두 워크스페이스 소유자에게 자동 이전됩니다.",
        delete_btn: "워크스페이스 삭제",
        save_btn: "저장",
        extenstion: "업로드 가능한 확장자 : png, jpg, jpeg",
        form_label: "워크스페이스 이름",
        form_placeholder: "워크스페이스명을 입력해주세요.",
        form_helpertext:
          "조직이나 회사 이름을 사용할 수 있습니다. 이름을 100자 이내로 입력하세요.",
        form_helpertext_error: "최소 1자 이상 입력해주세요.",
      },
      member: {
        title: "워크스페이스 멤버 관리",
        email_invite: "이메일 초대",

        max_member: {
          title: "결제하신 플랜의 멤버 초대수를 모두 사용하셨습니다.",
          sub_title: "추가 결제를 통해 필요한 멤버 수 만큼 추가가 가능합니다.",
          confirm: "인원 추가 문의",
        },
        transfer_owner: "소유권 이전",
      },
      info: {
        level: {
          title: "사용자 등급",
          labels: {
            member: "연구원",
            admin: "관리자",
          },
        },
        period: "플랜 유효 기간",
        researchers: "워크스페이스 멤버",
      },
      banner: {
        extension: "플랜 연장 문의하기",
        more_members: {
          title: "멤버 초대수를 모두 사용하셨습니다.",
          sub_title: "추가 결제를 통해 필요한 멤버 수 만큼 추가가 가능합니다.",
          button_text: "워크스페이스 인원 추가 문의",
        },
      },
      sub_title: {
        admin_version: "멤버 관리",
        member_version: "워크스페이스 멤버",
      },
      invite: {
        link_text: "초대 링크",
        copy_button: "링크 복사",
        select_box_text: {
          member: "연구원",
          admin: "관리자",
        },
        submit_button: "멤버 초대",
        input_placeholder: "입력한 이메일로 초대 메일이 발송됩니다.",
        guide_text: "관리자 지정은 최대 3명까지 가능합니다.",
        helper_text: {
          email_format: "이메일 형식으로 입력해주세요.",
          already_hold: "이미 존재하는 유저입니다",
        },
      },
      table: {
        input_placeholder: "검색어를 입력해주세요.",
        remove_researcher_button: "멤버 삭제",
        add_member_button: `워크스페이스 멤버에 추가`,
        manage_external_researcher: `휴면 계정 관리`,
        pending_status_banner: {
          time_left: {
            title: "해당 이메일로 회원가입 시 워크스페이스 활동이 가능합니다",
            button_text: "초대 취소",
          },
          expired_time: {
            title: "이메일 초대가 만료되었습니다.",
            button_text_1: "재초대",
            button_text_2: "삭제",
          },
        },
        header: {
          name: "이름",
          role: "권한",
          email: "이메일",
          affiliationAndOrganization: "소속/기관",
          majorAndDepartment: "전공/부서명",
          signature: "서명",
          authentication: "인증현황",
        },
        body: {
          role: {
            owner: "소유자",
            admin: "관리자",
            member: "연구원",
            other_member: "휴면 계정",
          },
          sign_info: {
            is_registration: "등록",
            is_not_registration: "미등록",
          },
        },
        select_mode: {
          download_document_button: "소유권 이전 문서 다운로드",
        },
      },
      cta: {
        title: {
          default:
            "관리자는 삭제가 불가능하오니 멤버으로 권한 수정 후 삭제해주세요.",
          selected_user: (count: number) =>
            `${count}명의 멤버를 삭제하시겠습니까?`,
          remove_member: "삭제할 멤버를 선택해 주세요.",
          transfer_owner: "소유권을 이전할 1명의 멤버를 선택해주세요.",
        },
        button: {
          cancel: "취소",
          select_all: "전체 선택",
          remove: "삭제",
        },
      },
      popover: {
        title: "멤버 권한 설정",
        options: {
          admin: "관리자",
          member: "연구원",
        },
      },
    },
    workspace_empty: {
      title: "앗, 아직 워크스페이스가 없어요!",
      sub_title_01: "워크스페이스가 생성되지 않았습니다.",
      sub_title_02: "새 워크스페이스를 생성해보세요!",
      create: "워크스페이스 생성하기",
    },
    auth_progress: {
      title: "블록체인 인증현황",
      folder_to_save: "저장폴더",
      electronic_signature: "전자서명",
      hash: "해시",
      auth: "인증",
      subtitle1:
        "업로드된 모든 파일은 블록체인에 암호화되어 안전하게 보관됩니다.",
      subtitle2:
        "작성 시점을 인증받아 확실한 법적 효력을 만들고, OCR 기술을 통해 사진 속 텍스트를 검색해 보세요!",
      empty_desc1: "연구노트를 추가하여",
      empty_desc2: "나만의 블록체인을 만들어 보세요!",
      footer: {
        username_suffix: "(님)의",
        file_count_suffix: "개 파일",
        desc: "이 블록체인으로 안전하게 보관되고 있습니다.",
      },
      block_content_description: (blockNum: string) =>
        `구노 서비스 전체 블록 중 <b>${blockNum}번째 인증 됨<b>`,
      nth_block: (blockNum: string) => `${blockNum}번째 인증 블록`,
      certified_at: (timestamp: string) => `${timestamp} 인증완료`,
      no_certified: {
        title: "인증된 블록체인이 없습니다.",
        desc_01: "연구노트 탭에서",
        desc_02: "새 파일을 추가해보세요!",
      },
    },
    main: {
      plan_title: "플랜 정보",
      plan_card: {
        name: "플랜 명",
        period: "플랜 기간",
        member: "플랜 구성원",
      },
      upcoming: "시작 예정",
      guest_title: "구노 시작하기",
      add_note_msg: "내PC에서 파일을 끌어다 놓으세요.",
      sub_text:
        "jpg, jpeg, png, svg, tiff, webp, heic, doc, docx, ppt, pptx, pdf, xls, xlsx 형식을 지원합니다.",
      summary: "대시보드",
      strong_box_home: "Strong Box 홈",
      goono_start: "구노 시작하기",
      activity_button: "활동내역",
      bookmark_notes: "즐겨찾는 연구노트",
      empty_bookmark_notes: "즐겨찾는 연구노트가 없습니다.",
      view_all: "상세보기",
      weekly_report: "주간 연구 활동 리포트",
      file_upload_report: "추가된 연구파일 현황",
      most_likely_tags: "자주쓰는 태그(#) Top5",
      tags_empty_1: "최근 일주일 내에 태그가 없어요.",
      tags_empty_2: "연구파일에 태그를 추가해보세요!",
      active_researchers: "이번주 활동 멤버",
      goono_guide: "구노 사용 가이드",
      data_room_guide: "데이터룸 가이드",
      view_faq: "자주 묻는 질문 보기",
      amount: "분 분량",
      guide: {
        total_guide_title_01: "구노 기능에 대해 소개드릴게요!",
        total_guide_title_01_highlight: "구노 기능",
        data_room_guide_title_01: "데이터룸 기능에 대해 소개드릴게요!",
        data_room_guide_title_01_highlight: "데이터룸 기능",
        data_room_guide_paid_question: `데이터룸 가격이 궁금하신가요?`,
        data_room_guide_paid_question_highlight: `데이터룸 가격`,
        data_room_guide_paid_question_button: `가격 안내`,
        total_guide_title_02: "상세 메뉴얼이 필요하신가요?",
        total_guide_title_02_highlight: "상세 메뉴얼",
        total_guide_subtitle:
          "전체 기능에 대한 안내를 한번에 볼 수 있는 노션 링크와 ",
        total_guide_subtitle_02: "기능 소개에 대한 영상 링크입니다.",
        pdf_download: "PDF 메뉴얼 다운로드",
        notion_guide: "노션 가이드",
        video_link: `영상 가이드`,
        start_name: "구노 시작하기",
        start_caption: "구노 서비스 둘러보기",
        part1_name: "연구노트 생성/관리하기",
        part1_caption: "#연구노트 생성 #멤버초대 #연구노트 정보 수정",
        part2_name: "연구파일/폴더 관리",
        part2_caption: "#연구파일 추가 #파일 삭제 #폴더 생성 # 폴더 수정",
        part3_name: "서비스 연동",
        part3_caption: "#Github 연구노트에 연동 #Github 연동 목록",
        part4_name: "연구노트 완성하기",
        part4_caption: "#연구노트 PDF 다운로드",
        part5_name: "연구노트 활동내역 확인하기",
        part5_caption: "#활동내역 #협업",
        sample_project_title: "샘플 연구노트",
        sample_project_subtitle_01: "샘플연구노트로 구노를 체험해보세요!",
        open_sample_button: "바로가기",
        help_center_desc: (type: "goono" | "dataRoom") =>
          `${
            type === "goono" ? "구노" : "데이터룸"
          }에 대해 궁금한 점이 있으신가요?`,
      },
      recent_history: {
        title: "최근 내역",
        alert: "최근 20개의 업로드 및 다운로드 내역을 제공합니다.",
        upload_btn: (windowWidth: number) =>
          `${windowWidth > 600 ? "최근 업로드된 파일" : "최근 업로드"}`,
        download_btn: (windowWidth: number) =>
          `${windowWidth > 600 ? "최근 다운로드 내역" : "최근 다운로드"}`,
        created_share_link_btn: (windowWidth: number) =>
          `${windowWidth > 600 ? "최근 생성된 공유링크" : "최근 공유링크"}`,
        no_upload: "최근 업로드된 파일이 없습니다.",
        no_link: "최근 생성된 공유링크가 없습니다.",
        no_download: "최근 다운로드된 내역이 없습니다.",
      },
      expired_plan: {
        title: "플랜 기간 만료",
        desc: "기간 연장을 원하신다면 언제든지 문의주세요.",
      },
      free_plan_guard: {
        title: "플랜 구입 문의",
        desc: (type: "goono" | "dataRoom") =>
          `${
            type === "goono" ? "구노" : "데이터룸"
          } 서비스를 이용하시려면 플랜 구입이 필요합니다. 유료 플랜 구매를 원하신다면 언제든지 문의주세요.`,
      },
      free_plan_banner: {
        title:
          "체험판을 신청하시면 아래 기능들을 14일간 무료로 사용할 수 있어요!",
        button: "체험판 신청하기",

        research_note: {
          title_01: "파일 추가 횟수 무제한",
          title_02: "팀 공동연구",
          title_03: "시점인증 포함 PDF 다운로드",
          title_04: "연구활동 분석 대시보드 제공",
          desc_01:
            "파일을 무제한으로 올릴 수 있으며 \n 블록체인에 안전하게 보관하세요.",
          desc_02: "연구자료 공유를 통해 \n 연구성과를 올려보세요.",
          desc_03:
            "한국전자 인증서를 통해 최고의 \n 전자인증 서비스를 제공합니다.",
          desc_04:
            "관리자 대시보드를 통해 멤버 및 \n 데이터를 편리하게 관리해보세요.",
        },
        data_room: {
          title_01: "파일 추가 횟수 무제한",
          title_02: "NDA 전자서명 첨부 가능",
          title_03: "공유링크 생성 가능",
          title_04: "인사이트 확인가능",
          desc_01:
            "파일을 무제한으로 올릴 수 있으며 \n 블록체인에 안전하게 보관하세요.",
          desc_02:
            "NDA 파일을통해 업무 과정 중 \n 내부 비밀이 유출되지 않게 관리하세요.",
          desc_03: "공유링크 생성을 통해 \n 안전하게 파일을 전송해보세요.",
          desc_04:
            "인사이트를 통해 투자유치시 \n 우리 회사의 강점을 확인해보세요.",
        },
      },
      data_room: {
        tutorial_card: {
          title: "데이터룸 기능 튜토리얼",
          button: "시작하기",
        },
      },
    },
    my_page: {
      title: "마이페이지",
      change_image: {
        top: "사진",
        bottom: "변경",
      },
      name: "이름",
      email: "계정 주소",
      school: "소속기관",
      department: "전공/분야/부서명",
      sign: "전자 서명",
      required: "필수",
      edit_profile: "프로필 수정",
      edit_signature: "전자서명 수정",
      modify_password: "비밀번호 변경",
      cancel: "취소",
      reset: "초기화",
      save: "저장",
      fail: "사용자 정보 변경에 실패하였습니다.",
      loading: "사용자 정보를 변경 중입니다.",
      success: "성공적으로 변경되었습니다.",
    },
    payment_result: {
      due: "기간 : ",
      workspace_seats: "이용 가능한 인원 수",
      card: {
        text: "카드결제가 완료 되었어요!",
      },
      info_text: "이제 모든 기능을 무제한으로 사용하실 수 있어요.",
    },
    payment_V_bank: {
      user_info: {
        title: "결제 정보",
        body: "유료 플랜",
        badge: "입금 대기 중",
        account: "입금 계좌",
        amount: "입금 금액",
      },
      bankbook: {
        text1: "무통장 입금이 신청",
        text2: "되었어요!",
        text3: "결제 완료 후 모든 기능을 무제한으로 사용하실 수 있어요.",
      },
    },
    project_inside: {
      title: "연구노트 상세",
      title_sub: "연구파일",
      empty_note: "작성된 연구파일이 없습니다.",
      research_file: {
        more_tags: (count: number) => `외 ${count}개 태그`,
        remove: "파일삭제",
        file_info: {
          conver_pdf: "PDF로 변환 중입니다",
          create_thumb: "미리보기 생성 중입니다",
          fail_err: "연구파일 추가 실패했습니다",
          thumb_err: {
            p1: `미리보기 생성이`,
            p2: `불가능합니다`,
          },
        },
      },
      file_alarm: {
        title: `업데이트 알림`,
        description: `파일명이 리스트로 추가되었습니다.
        기존에 올렸던 파일은 해시값으로 파일명이 임시로 설정되었으니
        파일명을 변경해서 사용해주세요.`,
      },
      date_picker: {
        sun: `일`,
        mon: `월`,
        tue: `화`,
        wed: `수`,
        thu: `목`,
        fri: `금`,
        sat: `토`,
        select_end_date: `종료일을 선택해 주세요.`,
      },
      tag_ocr_search: {
        title: `Tag 검색`,
        guide_text: `태그를 검색해보세요`,
        recently_tag: `최근 사용한 태그`,
        related_tag: `연관 태그`,
        no_data: `결과가 없습니다`,
        search_tag: {
          start: `직접 입력한`,
          end: ` 태그 검색`,
        },
      },
      unknown_project_dialog: {
        title: "해당 링크는 유효하지 않습니다.",
        body_msg: "삭제된 연구노트입니다.",
        button_msg: "확인",
      },
      unknown_project_member_dialog: {
        title: "해당 노트에 진입권한이 없습니다.",
        body_msg:
          "죄송합니다. 현재진입하신 연구노트는 진입권한이없습니다.\n노트 소유권한에게 문의 부탁드립니다.",
        button_msg: "확인",
      },
      CTA: {
        download_button: "연구노트 다운로드",
        download: (folder_cnt: number, file_cnt: number) =>
          `폴더 ${folder_cnt}개, 파일 ${file_cnt}개`,
        delete: (file_cnt: number) =>
          `선택한 연구파일 ${file_cnt}건을 삭제하시겠습니까?`,
        move: (file_cnt: number) =>
          `선택한 연구파일 ${file_cnt}건을 이동하시겠습니까?`,
      },
      button: {
        modify_note: "연구노트 수정",
        log_history: "활동내역",
        download: "연구노트 다운로드",
      },
      label: {
        manager: "책임자",
        number: "과제번호",
        period: "과제기간",
        files: "연구파일",
      },
      search_popover: {
        none_result: `조회된 내역이 없습니다.`,
        title: "연구노트 검색",
        placeholder: "연구노트 이름 검색",
        all: "전체 연구노트",
        share: (data: number) => `${data}명과 공유`,
        select_limit_dialog: {
          title: "연구노트 선택 한도 초과",
          body_msg: (limit_count: number) =>
            `죄송합니다.\n관리자 페이지에서 연구노트 선택은 최대 ${limit_count}건 까지 가능합니다.`,
          button_msg: "확인",
        },
      },
      filter: {
        all_member: "전체 멤버",
        recent_authenticate: "시점인증 최신",
        first_authenticate: "시점인증 과거",
        recent_written: "작성일자 최신",
        first_written: "작성일자 과거",
        move_note: "파일 이동",
        delete_note: "파일 삭제",
        writer: "작성자",
        search_member: "멤버 검색",
        search_member_placeholder: "멤버 이름, 이메일 검색",
        file_type: "파일 형식",
        file_name: "파일명",
        search_file_type: "연구 파일 형식 검색",
        search_tag: "Tag 검색",
        search_ocr: "OCR 검색",
        related_tag: "최근 사용한 태그",
        no_related_tag: "최근 사용한 태그 없음",

        //--
        file_title: "연구파일 검색",
        period: "작성일자 선택",
        no_period: "선택된 기간이 없습니다.",
        search: "검색",
        add_keyword: "추가",
        init_search: "초기화",
        success_search: "검색 옵션이 변경되었습니다.",
        detail_search: "상세검색",
        detail_search_result: (file: number) =>
          `상세검색을 통해 '${file}' 개의 연구파일을 검색했습니다.`,
        clear: "검색 초기화",
        total_period: "과제기간 전체",
        add_file: "연구파일 추가",
      },
      table_cell: {
        file_info: "연구파일 정보",
        format: "형식",
        written: "작성일자",
        authentication: "시점 인증 일자",
        author: "작성자",
        add_fail: "파일 추가 실패.",
        converting_pdf: "PDF로 변환 중입니다.",
        creating_preview: "미리보기 생성 중입니다.",
        delete_button: "파일 삭제",
      },
      dnd_box: {
        title: "마우스로 드래그 해서 연구파일을 추가해주세요.",
        allow_file_info:
          "추가 가능한 파일 유형 : Jpeg, Jpg, Png, SVG , tiff , Webp , Heif , Heic , Doc , Docx, PPTX , PPT , Xls , Xlsx , PDF",
      },
      note_more: {
        title: "파일 옵션",
      },

      select_note: "인증 받을 노트를 선택하세요",
      download_note: "다운받을 연구노트를 선택하세요",
      delete_note: "삭제할 연구노트를 선택하세요",
      bin_folder: "폴더 비우기",
      alert: {
        title: "비우기",
        body: "폴더 내의 모든 노트를 삭제하시겠습니까? 한 번 삭제하면 복구할 수 없습니다.",
      },
      delete_note_alert:
        "해당 노트를 삭제하겠습니까? 삭제 후 복구할 수 없습니다.",
      delete_notes_alert: {
        title: "선택된 노트 삭제",
        msg1: "선택된 노트 ",
        msg2: "개를 삭제하시겠습니까? 삭제 후 복구할 수 없습니다.",
      },
      user_count: "연구자",
      user_count_postfix: "명",
      loading: "연구노트 파일 동기화 중입니다.",

      share: {
        create_link: "공유 링크를 생성 중입니다",
      },
      folder: {
        folder_list: "연구폴더 리스트",
        create: "연구폴더 생성",
        path_home: "연구노트 홈",
        option_title: "연구폴더 옵션",
        edit_name: "폴더명 수정",
        github_link: "서비스 연동",
        github_link_delete: "서비스 연동 해제",
        delete: "폴더 삭제",
      },
      folder_grid: {
        folder: "연구폴더",
        empty_folder: "생성된 폴더가 없습니다",
        more_folder: (data: number) => `${data}개 폴더 더보기`,
        fold: "폴더 접기",
        guide: "연구폴더는 최대 30개까지 생성 가능합니다.",
      },
      drawer: {
        title: {
          note: "노트별 활동내역",
          project: "노트별 상세 활동내역",
          download: "내 다운로드 내역",
        },
        all: "전체",
        activity: (_num: number) => `활동`,
        all_activities: "모든 활동",
        options: "검색 기간 선택",
        activity_update_open: "수정사항 보기",
        activity_update_close: "수정사항 닫기",
        member_options: {
          all: "전체이력",
          personal: "개인이력",
        },
        activity_options: {
          create: "생성",
          modify: "수정",
          delete: "삭제",
          download: "다운로드",
          upload: "파일추가",
          move: "파일이동",
          select_all: "전체 유형 선택",
          unselect_all: "전체 유형 해제",
        },
        activity_modify_options: {
          project_name: "과제명",
          project_number: "과제번호",
          project_manager: "책임자",
          delete_option: "노트 소유권한 외  삭제 방지",
          download_option: "연구파일 다운로드 방지",
          date: "기간",
          owner: "소유권 변경",
          member_invite: "멤버 초대",
          member_delete: "멤버 삭제",
          move_note: "현재 연구노트",
          move_other_project: "다른 연구노트",
          allow_delete: "폴더 및 파일 삭제 허용",
          prevent_delete: "폴더 및 파일 삭제 금지",
          allow_download: "연구파일 다운로드 허용",
          prevent_download: "연구파일 다운로드 금지",
        },
        num: "건",
        period: "기간옵션",
        period_options: {
          all: "기간 전체",
          one_week: "최근 1주일",
          one_month: "최근 1달",
          three_month: "최근 3달",
          custom_period: "사용자 선택",
          total_project_period: "작성된 과제 기간",
          all_period: "전체 플랜 기간",
        },
        date_pick: "검색 기간 선택",
        date: {
          today: "오늘",
          mon: "월",
          tue: "화",
          wed: "수",
          thu: "목",
          fri: "금",
          sat: "토",
          sun: "일",
        },
        start: "시작일",
        end: "종료일",
        no_date: "없습니다",
        member: (_num: number) => "멤버",
        person_num: "명",
        all_members: "모든 멤버",
        search_members: "멤버 검색",
        members_placeholder: "멤버 이름, 이메일 검색",
        select_all_members: "전체 멤버 선택",
        unselect_all_members: "전체 선택 해제",
        clear: "필터 초기화",
        no_result: "해당 기간 내 활동 내역이 없습니다",
        log: {
          to_folder: "폴더에서",
          to_folder_2: "폴더로",
          to_note_kr: "에",
          from: (folder_name?: string) =>
            `${folder_name !== undefined ? "폴더" : ""}에서`,
          project: "연구노트의",
          upload: (num: number) => `${num}개의 연구파일을 추가 했습니다.`,
          delete: (num: number) => `${num}개의 연구파일을 삭제 했습니다.`,
          download: (num: number) =>
            `연구노트에서 ${num}개의 연구파일을 다운로드 했습니다.`,
          originDownload: (num: number) =>
            `연구노트에서 ${num}개의 원본파일을 다운로드 했습니다.`,
          originDownlaodHighlight: (num: number) =>
            `${num}개의 원본파일을 다운로드`,
          upload_highlight: (num: number) => `${num}개의 연구파일을 추가`,
          delete_highlight: (num: number) => `${num}개의 연구파일을 삭제`,
          download_highlight: (num: number) => `${num}개의 연구파일을 다운로드`,
          delete_folder: (num: number) =>
            num > 0
              ? `폴더 및 ${num}개의 연구파일을 삭제 했습니다.`
              : `폴더를 삭제 했습니다.`,
          delete_folder_highlight: (num: number) =>
            num > 0 ? `폴더 및 ${num}개의 연구파일을 삭제` : `폴더를 삭제`,
          create_comment: (num: number) =>
            `연구파일 ${num}P에 코멘트 를 남겼습니다.`,
          create_comment_highlight: (num: number) => `${num}P에 코멘트`,
          create_comment_all: "연구파일 전체 페이지에 코멘트를 남겼습니다.",
          create_comment_all_highlight: "전체 페이지에 코멘트",
          create_folder: "폴더를 생성 했습니다.",
          create_folder_highlight: "폴더를 생성",
          update_folder: "폴더명을 수정 했습니다.",
          update_folder_highlight: "폴더명을 수정",
          update_file: "파일명을 수정 했습니다.",
          update_file_highlight: "파일명을 수정",
          from_en: "",
          from_kr: " 에서 ",
          prefix_kr: " 으로 ",
          git_link: (folder_name?: string) =>
            `${folder_name !== undefined ? "폴더" : ""}에 연동된`,
          upload_github: "레포지토리에서 연구파일이 추가 되었습니다.",
          upload_github_highlight: "레포지토리에서 연구파일이 추가",
          move: (num: number) => `${num}개의 연구파일을 이동 했습니다.`,
          move_highlight: (num: number) => `${num}개의 연구파일을 이동`,
          create_project: "새 연구노트를 생성 했습니다.",
          create_project_highlihght: "새 연구노트를 생성",
          transfer_project_owner: (owner: string) =>
            `연구노트 소유권을 ${owner}님 으로 변경 했습니다.`,
          transfer_project_owner_highlight: (owner: string) =>
            `${owner}님 으로 변경`,
          delete_project: "연구노트를 삭제 했습니다.",
          delete_project_highlight: "연구노트를 삭제",
          invite_member: (member: string) =>
            `연구노트에서 ${member} 님을 초대 했습니다.`,
          invite_member_highlight: (member: string) => `${member} 님을 초대`,
          delete_member: (member: string) =>
            `연구노트에서 ${member} 님을 삭제 했습니다.`,
          delete_member_highlight: (member: string) => `${member} 님을 삭제`,
          update_project_info: "연구노트 정보를 수정 했습니다.",
          update_project_info_highlight: "정보를 수정",
          update_project_option: "연구노트 설정을 변경 했습니다.",
          update_project_option_highlight: "설정을 변경",
        },
        home: "연구노트 홈",
        cancel: "취소",
        submit: "확인",
        search: "조회",
      },
      breadcrumb: {
        create_folder: (width: number) =>
          width > 600 ? "연구폴더 생성" : "폴더생성",
        edit_folder: "폴더명 수정",
      },
      initial: {
        title: "연구노트 작성 시작",
        sub_title:
          "새 연구노트 생성을 축하합니다. \n 연구 파일 추가 또는 폴더를 생성해 연구기록을 관리해 보세요.",
        add_file: "파일추가",
        add_file_guide:
          "파일을 Drag&Drop 또는 파일 탐색기를 통해 연구 파일업로드가 가능합니다.",
        new_folder: "폴더생성",
        new_folder_guide:
          "연구파일을 폴더를 활용해 효율적으로 관리할 수 있습니다.",
        link_github: "깃허브 연동",
        link_github_guide:
          "깃허브 연동으로 커밋 코멘트 기록을 매일 12시에 자동업로드 합니다.",
      },
      sample_note: {
        description:
          "샘플 연구노트는 이해를 돕기위해 임시로 작성된 연구노트입니다. 해당 연구노트의 내용은 일부 편집 기능만 가능합니다.",
      },
    },
    upload_screen: {
      title_upload: "연구 파일추가",
      subtitle_upload: "연구파일 추가 목록",
      guidetext_upload: "드래그 앤 드랍을 통해 리스트 순서 변경이 가능합니다.",
      written_title: "작성일자",
      written_guide:
        "연구 진행 후 수기기록 또는 자료화 하셨던 날짜를 선택해주세요.",
      authentication_title: "시점 인증 날짜",
      authentication_guide:
        "시점인증 날짜는 파일추가한 시간으로 제공되며, 수정이 불가능 합니다. (시점 인증 마크가 부여됩니다.)",
      authentication_guide_highlight: "수정이 불가능",
      note_title: "연구노트",
      note_guide: "연구파일을 추가 할 연구노트를 선택해주세요",
      all: "전체",
      folder_title: "연구폴더",
      folder_guide: "선택하지 않을 시 연구노트 홈에 파일추가 됩니다.",
      sorting: "연구파일 정렬",
      google_drive: "구글 드라이브",
      title_google: "구글 드라이브에서 파일추가",
      subtitle_google: "구글 드라이브 파일 목록",
      alert_google:
        "구글 문서파일( 문서, 스프레드시트,프레젠테이션 )은 10mb 이하로만 연구파일로 추가 가능하며, 구글 드라이브에서 일반적인 파일은 50mb 이하의 jpg, jpeg, png, svg, tiff, webp, heic, doc, docx, ppt, pptx, pdf, xls, xlsx 형식 파일에 한해 추가 가능합니다.",
      guidetext_google:
        "문서의 크기가 10MB이하인 구글 Docs, Sheets, Slides는 구글 드라이브 정책상 pdf로 변환하여 구노에 가져올 수 있습니다.",
      google_placeholder: "구글 드라이브 파일명을 검색해보세요",
      google_select: (note: number) => `${note} 개 선택`,
      google_select_placeholder: "선택하신 목록 내 파일명을 검색해주세요.",
      total_list: "전체목록",
      selected_file: "선택한 파일",
      file: "파일",
      search_list: "조회된 목록",
      no_searched_file: "조회된 파일이 없습니다.",
      google_path: "구글 드라이브 경로 선택",

      button: {
        logout: "로그아웃",
        add: "파일 추가",
        home: "연구노트 홈",
      },
      filter: {
        custom: "사용자설정",
        modify_recent: "수정일자 <detail>최신순<detail>",
        modify_first: "수정일자 <detail>오래된순<detail>",
      },
    },
    preview: {
      preview: "미리보기",
      file_detail: "파일 상세",
      file_delete: "파일 삭제",
      file_download: "연구노트 다운로드",
      origin_file_download: "원본파일 다운로드",
      file_empty: "파일이 없습니다",
      before_file: "이전 파일",
      after_file: "다음 파일",
      writtenAt: (date: string) => `작성일 : ${date}`,
      comment: "코멘트",
      file_info: "파일 정보",
      search_placeholder: "코멘트 검색",
      update_written_at_fail: "작성 날짜 수정 과정에서 오류가 발생했습니다.",
      update_written_at_success: "작성날짜 수정 완료",
      comment_empty: "작성된 코멘트가 없습니다",
      comment_placeholder:
        "코멘트를 입력해주세요 \n@를 사용해 멘션이 가능합니다 ",
      comment_error: "코멘트 길이가 너무 깁니다. 1800 자 이내로 작성해주세요",
      comment_add: "코멘트가 추가되었습니다.",
      tooltip: `연구노트 작성권한이 있는 사용자만 
              작성날짜를 수정할 수 있습니다.`,
      tag_empty: "작성된 태그가 없습니다",
      edit_tag: "태그 편집",
      add_tag: "태그 추가하기",
      all: "전체",
      all_page: "전체 페이지",
    },
    project: {
      title: "연구파일",
      enter: "입력하기",
      edit: "수정하기",
      study_name: "과제 명",
      study_period: "과제 기간",
      study_info: "과제 정보",
      delete_study_notes: "연구노트 삭제하기",
      download_study_notes: "연구노트 다운받기",
      no_study_info:
        "과제 정보가 없습니다. 과제 정보를 입력하면 연구노트 PDF에 반영 됩니다.",
      sort_by_last_uploaded: "업로드 최신순",
      sort_by_first_uploaded: "업로드 오래된 순",
      sort_by_last_written: "작성일자 최신 순",
      sort_by_first_written: "작성일자 오래된 순",
      filter_user: "작성자",
      sort_by_first_name: "이름 오름차순",
      sort_by_last_name: "이름 내림차순",
      sort_by_first_created: "생성일자 최신",
      sort_by_last_created: "생성일자 과거",
      favorite: "즐겨찾는 연구노트",
      default: "모든 연구노트",
      grid: {
        more_pinProject: (data: number) => `${data}개 연구노트 더보기`,
        fold: "연구노트 접기",
      },
      create: {
        title: "새 연구노트 생성",
        stepper: {
          step1: "과제 정보 입력",
          step2: "연구노트 설정",
          step3: "멤버 관리",
          step4: "연구노트 삭제",
        },
        section: {
          step_title1: "과제 정보 입력",
          step_title2: "연구노트 설정",
          step_title3: "멤버 관리",
          step_title4: "연구노트 삭제",
          required: "필수 입력항목입니다.",
        },

        form: {
          project_name_label: "과제 명",
          project_name_placeholder: "과제명을 입력해주세요.",
          project_name_helpertext:
            "과제명은 필수 입력 사항입니다. 잘 모른다면 가제로 적어두고 원하실 때 수정 가능합니다.",
          project_name_helpertext_error:
            "과제명은 100글자 이내로 입력해주세요.",
          project_owner_label: "과제 책임자",
          project_owner_placeholder: "책임자의 성함을 입력해주세요.",
          project_owner_helpertext: "최대 20자 입력 가능합니다.",
          project_owner_helpertext_error:
            "과제 책임자는 20글자 이내로 입력해주세요.",
          project_number_label: "과제 번호",
          project_number_placeholder: "과제번호를 입력해주세요.",
          project_number_helpertext: "최대 25자 입력 가능합니다.",
          project_number_helpertext_error:
            "과제 번호는 25글자 이내로 입력해주세요.",
          date_optoin_3month: "3개월",
          date_option_6month: "6개월",
          date_option_9month: "9개월",
          date_option_year: "1년",
          date_option_user: "사용자 지정",
          project_date_label: "과제 기간",
          project_date_start_label: "시작일",
          project_date_end_label: "종료일",
          project_date_helpertet:
            "시작일과 종료일은 클릭시 상세 일자를 변경할 수 있습니다.",
          pdf_preview_title:
            "입력하신 정보는 연구노트 표지에 자동 입력 됩니다.",
          pdf_preview_button: "연구노트 표지 미리보기",
          delete_available_label: "소유권한 외 연구폴더 / 파일 삭제 방지",
          delete_available_helpertext:
            "연구노트 소유권한 외 다른 멤버가 연구노트 내 폴더 / 파일 삭제가 불가능합니다.",
          delete_available_switch_false: "삭제 허용",
          delete_available_switch_true: "삭제 불가",
          download_available_label:
            "노트 소유권한 외 연구노트(PDF, 원본) 다운로드 방지",
          download_available_helpertext:
            "연구노트 소유권한 외 다른 멤버의 연구노트(PDF, 원본) 다운로드 활동이 불가능 합니다.",
          download_available_switch_false: "다운로드 허용",
          download_available_switch_true: "다운로드 불가",
          invite_sub_title:
            "초대할 멤버의 메일을 모른다면, 링크를 복사해서 전달해주세요.",
          invite_helpertext: "이메일 혹은 이름으로 검색 가능합니다.",
          invite_helpertext_edit_screen:
            "이메일 혹은 이름으로 검색 가능합니다.",
          change_owner: "소유권 변경",
          owner_note_guide: "님은 본 연구노트의 소유권한 입니다.",
          rw_permissions: "연구파일 추가, 노트 내 파일 이동",
          add_file: "연구파일 추가",
          add_file_guide:
            "파일 삭제 및 연구노트 다운로드 소유권한에게 권한을 요청하세요.",
          view_note: "연구노트 열람",
          view_note_guide: "연구노트 다운로드는 소유권한에게 권한을 요청하세요",
          delete_remind: "연구노트 삭제 주의 사항",
          delete_remind_guide:
            "연구노트 삭제시 작성된 연구 파일 및 연구노트는 영원히 복구 불가능 합니다.",
          invite_member: "초대할 연구노트 멤버",
          invite_member_empty: "공동멤버이 없습니다.",
          team_member: "워크스페이스 멤버",
          invite_all_team_member: "워크스페이스 멤버가 전부 초대되었습니다.",
          permission_option: "사용자 권한 옵션",
        },
      },
      sample_note: {
        landing: {
          top: "유료 플랜이 없어 연구노트 작성이 불가능합니다.",
          bottom: "하지만 샘플용 연구노트를 제공해드립니다.",
        },
        show_button: "샘플 연구노트 보기",
      },
    },
    purchase: {
      pay: {
        plan: "구노프리미엄결제",
        month: "개월",
        user: "인",
      },
      title: "결제 정보",
      sub_text: {
        text1: "Premium 플랜",
        text2: "을",
        text3: " 선택하셨습니다.",
      },
      method: "결제 방법",
      card: "신용카드",
      bankbook: "무통장 입금",
    },
    register_email: {
      title: "회원가입",
    },
    register: {
      title: "회원가입",
    },
    search_more: {
      title: {
        fileName: "파일명",
        hash: "태그",
        ocr: "OCR 문서 내",
        folder: "폴더",
      },
      result: "에 대한 검색결과",
    },
    search_result: {
      result: "에 대한 검색결과",
      no_result: "검색 결과가 없습니다.",
      fail_search: {
        text1: "단어와 철자가 정확한지 확인하시거나",
        text2: "다른 키워드를 검색해보세요!",
      },
      fileName: "파일명",
      hash: "태그",
      view_all: "모두보기",
      ocr: "OCR 문서 내",
      for_prime: "프리미엄 유저만을 위한 기능입니다.",
      project: "연구노트",
      folder: "연구폴더",
    },
    security: {
      subtitle_1: "접속 IP 설정",
      subtitle_2: "접속 정보 확인",
      disable_feature: "모든 IP에서의 GOONO 접속을 허용합니다.",
      include_admin: "관리자는 IP 접속 제한 없이 접속",
      current_ip_addr: "현재 접속한 IP 주소",
      get_ip_addr: "IP 주소를 가져오는 중입니다...",
      label_access: "IP 접근 설정",
      label_exception: "예외 IP 등록",
      allow: "허용",
      block: "차단",
      add: "추가",
      placeholder_ip: "IP 주소를 입력해주세요.",
      delete: "삭제",
      devices: "접속 기기 수",
      device_unit: "개",
      latest_access_time: "최근 접속 기간",
      latest_access_device: "최근 접속 기기",
      access_time: "접속시간",
      access_name: "이름",
      access_ip: "접속 IP",
      access_device: "접속 기기",
      access_nation: "접속 국가",
      no_access: "접속 내역 없음",
      acl_error_format: "IPv4형식에 맞지 않습니다.",
    },
    dashboard_admin: {
      reset_search: "검색 초기화",
      period: "기간",
      week: "이번 주",
      month: "최근 한 달",
      month_6: "최근 6개월",
      select: "직접 입력",
      all: "전체",
      close: "닫기",
      member_col: "멤버 별",
      member_all: "전체 멤버",
      member_selected: "선택된 멤버",
      member_unit: "명",
      note_unit: "개",
      title: "워크스페이스 내 연구노트 현황",
      select_member: "멤버 선택하기",

      fixed_filter: {
        period: `기간`,
        note: `연구노트`,
        all_note: `전체 연구노트`,
        note_unit: `권`,
        researcher: `멤버`,
        all_researcher: `전체 멤버`,
        researcher_unit: `명`,
      },

      tab: {
        overview: "전체요약",
        activities: "멤버 활동",
        folder_view: "워크스페이스 연구노트 확인하기",
        notes: "연구노트",
      },

      note_list: {
        search_placeholder: `연구노트 이름으로 검색해 보세요.`,
        download_excel: `엑셀 다운받기`,
        filter_option: {
          title: `검색 옵션`,
          all: `전체`,
          note_name: `과제명`,
          admin: `소유권한`,
          owner: `책임자`,
        },
      },

      overview: {
        subTitle:
          "연구파일 현황의 그래프는 시점인증 날짜를 기준으로 그래프가 활성화됩니다.",

        title_1: "요약",
        description_1: "* 연구노트 업로드 현황의 그래프는 작성 날짜가 아닌",
        description_2: "연구노트의 시점 인증 날짜",
        description_3: "를 기준으로 그래프가 활성화됩니다.",
        graph_1: "연구노트 업로드 현황",
        graph_2: "기간 내 활성 멤버",
        graph_3: "기간 내 추가 된 연구노트 개수",
        graph_4: "평균 연구노트 업로드 개수",
        graph_5: "가장 많이 파일추가한 멤버",
        tooltip_1:
          "클릭 시 아래 자동으로 해당 멤버이 필터링 되어 결과 화면에 반영됩니다.",
        no_active_members: "기간 내 파일추가한 멤버가 없습니다.",
        title_2: "폴더 활동",
        graph_6: "가장 많이 파일추가된 폴더",
        tooltip_2_1: "클릭 시 아래 연구노트 현황에서 자동 검색됩니다.",
        tooltip_2_2: "더블 클릭 시 폴더 안 노트를 확인할 수 있습니다.",
        graph_7: "폴더 당 평균 연구노트 업로드 개수",
        graph_8: "연구노트 내 해시태그 순위",
        no_active_hashtag: "기간 내 작성한 해시태그가 없습니다.",
        tooltip_3: "더블 클릭 시 해당 해시태그의 검색 화면으로 이동합니다.",
        graph_9: "폴더 별 연구노트 현황",
        placeholder_input: "폴더 이름 혹은 멤버 이름으로 검색해보세요.",
      },
      activities: {
        title: "멤버 별 활동",
        graph_1: "멤버 별 업로드 현황",
        table_1: "멤버 별 업로드 횟수",
      },
      folder_log: {
        msg_1: "님이",
        msg_2: "에 연구노트를 업로드하였습니다.",
        folder: "폴더",
        btn: "노트 확인하기",
        all_folders: "전체 폴더 보기",
        joined_members: "워크스페이스 멤버",
        set_opened: "전체 펼쳐 보기",
        set_closed: "전체 접어 보기",
        select_all_member: "전체 사용자 선택",
      },
      logs: {
        period: `조회기간`,

        counting_title: {
          added_files: `추가된 연구파일`,
          average_file_cnt: `기간 내 평균 파일 수`,
          inactive_member: `연구활동 없는 멤버`,
        },

        options: {
          exclude_admin: `관리자 제외`,
          below_average_member: `평균미만 멤버`,
          related_member: `관련 멤버만 보기`,
        },
        tab: {
          upload: "연구파일 추가 현황",
          activity: "상세 연구활동 현황",
        },
        graph: {
          no_date: `데이터가 없습니다`,
          researcher: "멤버",
          upload_files: "추가 연구파일 (개)",
          badge: {
            admin: `관리자`,
            removed_user: `삭제`,
          },
          selector: {
            title: `연구파일 정렬`,
            most_file: `연구파일 많은순`,
            least_file: `연구파일 적은순`,
            name_a_to_z: `사용자명 오름차순`,
            name_z_to_a: `사용자명 내림차순`,
          },
        },
        table: {
          clear_search: `검색 초기화`,
          search_result_count: `조회된 건 수`,
          all_activity: `전체 활동옵션`,
          selected_activity: `선택된 활동옵션`,
          option_description: `확인 버튼을 눌러야 필터가 적용됩니다.`,
          none_activity: `데이터가 없습니다`,
          cell: {
            date: `날짜`,
            member: `활동 멤버`,
            note: `연구노트`,
            type: `활동종류`,
            content: `활동 내용`,
            change: "변경 항목",
            detail_change: "상세 변경 사항",
          },
          options: {
            create: {
              title: `생성`,
              note: `연구노트 생성`,
              folder: `연구폴더 생성`,
              comment: "코멘트 생성",
            },
            modify: {
              title: `수정`,
              note_info: `노트 정보 수정`,
              note_options: `노트 설정 수정`,
              note_owner: `노트 소유권 변경`,
              manage_member: `멤버 관리`,
              folder_name: `연구 폴더명 수정`,
              file_name: `연구 파일명 수정`,
            },
            delete: {
              title: `삭제`,
              note: `노트 삭제`,
              folder: `폴더 삭제`,
              file: `연구파일 삭제`,
            },
            add_file: `파일 추가`,
            move_file: `파일 이동`,
            download: `노트 다운로드`,
          },
        },
      },
      folder_view: {
        title: "전체 폴더",
        option: {
          all: "전체",
          name: "폴더 이름",
          member: "멤버",
          admin: "책임자",
        },
      },
      folder_item: {
        owner: "폴더 소유권한",
        my_auth: "나의 권한",
        members: "워크스페이스 멤버",
        type: "폴더 유형",
        project_period: "과제 기간",
        graph: "멤버 별 업로드 현황",
        total_notes: "총 업로드 된 연구노트 수",
        shared_folder: "공유된 폴더",
        private_folder: "개인 폴더",
      },
    },
    service_linkage: {
      empty: "연동되어 있는 폴더가 없어요.",
      title: "깃허브 연동",
      auto_auth: {
        text1: "자동 인증",
        text2: "받고 연구에만 집중하세요!",
      },
      status: "서비스 연동현황",
      status_guest: "프리미엄 유저만을 위한 기능입니다.",
      status_guest_desc: "업그레이드하여 더 많은 기능을 무제한으로 사용하세요.",
      github_box: {
        sub_text:
          "매일 밤 12시에 연동한 repository 의 commit history, comment, issue 가 올라갑니다.",
      },
    },
    landing_commons: {
      title:
        "연구자료는 프라이빗하게 보관하고,\n기밀자료는 자유롭게 공유하세요!",
      sub_title:
        "ISO, GS 인증 등 국내외 표준을 충족하는 서비스로 \n국내 2,000곳 이상의 조직에 최고의 기술을 제공합니다.",
      note_title: "전자연구노트 구노",
      note_desc: "가장 쉽고 빠르게 연구노트를 만들어보세요",
      strongbox_title: "스트롱박스",
      strongbox_desc: "스마트한 문서 보안/공유 솔루션",
      link_home: "홈페이지",
      link_blog: "블로그",
      link_terms: "서비스 이용약관",
    },
    sign_in: {
      title: "로그인",
      change_pw: "비밀번호 찾기",
    },
    sign_up: {
      title: "회원가입",
      check_user: "이미 회원이신가요?",
      register_email: "이메일로 시작하기",
      email_registered:
        "이메일 회원가입으로 등록된 계정입니다. 이메일 로그인을 이용해주세요.",
      sns_registered_1: "해당 계정은 이미",
      sns_registered_2: "로 가입된 계정입니다. 해당 플랫폼에서 시도해주세요.",
    },
    change_pw: {
      title: "비밀번호 재설정",
    },
    trend_chart: {
      title: "기간별 추이",
      button: "파일추가내역 상세",
    },
    application_trial: {
      title: "구노 체험판 신청",

      info: {
        title:
          "아래의 정보를 제공해주시면 다양한 유료기능을 14일간 무제한으로 사용 가능합니다.",
        organization: "소속기관명",
        phone: "연락처",
        seats: "도입 인원 수",
        button: {
          start_trial: "체험판 시작하기",
          view_more: "자세히 보기",
        },
        check: "개인정보 수집 및 활용 동의",
      },
      placeholder: "기관, 직장명",
      select_box_title: "도입 예상 인원",
      select_box_options: {
        ten: "10명",
        twenty: "10 ~ 20명",
        over_twenty: "20명 이상",
      },
      function_list: {
        title: "체험판 제공 기능",
        f1: "파일 추가 횟수 무제한",
        f2: "팀 공동연구",
        f3: "시점인증 포함 PDF 다운로드",
        f4: "연구활동 분석 대시보드 제공",
      },
    },
    research_note: {
      title: "연구노트",
      sub_title:
        "유료 플랜이 없어 연구노트 작성이 불가능합니다. 하지만 샘플용 연구 노트를 제공해 드립니다.",
      show_sample_note: "샘플 연구노트 보기",
    },
    onboarding: {
      title: "님 안녕하세요",
      sub_title: "전자 연구노트 서비스 구노의 회원가입을 축하드립니다.",
      third_title:
        "연구노트 작성을 시작하기 전 멤버님의 프로필 정보 작성을 먼저 진행하도록 하겠습니다.",

      image_format: "png, jpeg, jpg 파일 형식만 추가 가능합니다.",
      change_image: "사진변경",
      table: {
        title: {
          name: "이름",
          organization: "기관/직장",
          major: "전공/분야/부서명",
          sign: "전자서명(필수)",
        },
        placehoder: {
          name: "기관내 사용하시는 이름을 적어주세요.",
          organization: "기관 or 직장명을 적어주세요.",
          major: "전공, 분야 또는 부서명을 적어주세요.",
          sign: {
            title: "전자서명 등록",
            sub_title:
              "연구노트 내 작성자 인증을 위해 멤버님의 서명을 등록해 연구파일 업로드 할 때마다 자동으로 서명이 적용됩니다.",
          },
        },
        btn_next: "다음",
        helper_text: "글자 수가 초과되었습니다.",
      },
      loading: "프로필을 등록하고 있습니다..",
      success: "프로필이 등록되었습니다.",
      fail: "프로필 등록에 실패하였습니다.",

      step_1: "프로필 작성",
      step_2: "워크스페이스 생성",
      third_title_step_2: "마지막으로 워크스페이스 이름을 입력해 주세요.",

      workspace: {
        img_guide:
          "이미지 미등록 시 워크스페이스 명 앞 글자가 자동으로 이미지로 생성됩니다.",
        form_label: "워크스페이스 이름",
        placeholder: "워크스페이스명을 입력해주세요",
        banner:
          "이름은 워크스페이스 설정 - 개요에서 언제든지 변경할 수 있어요!",
        back: "이전",
        confirm: "구노 시작하기",
      },
    },
    ip_block: {
      sub_title: "IP 접근제한",
      title_01: "현재 접속하신 IP로는 해당 워크스페이스에 접근 불가합니다.",
      title_02: "다른 워크스페이스를 선택해주세요.",
      title_03: "워크스페이스 관리자에게 문의하세요.",
      go: "바로가기",
      create: "워크스페이스 생성",
    },
    workspace_create: {
      title: "새 워크스페이스 생성",
      confirm: "워크스페이스 생성하기",
      back: "홈으로 돌아가기",
    },
    strong_box: {
      table: {
        link: {
          header1: "활성",
          header2: "링크이름",
          header3: "파일이름",
          header4: "형식",
          header5: "유효기간",
          header6: "공유링크",
          options: "링크옵션",
        },
        file: {
          header1: "파일이름",
          header2: "생성일",
          header3: "생성자",
          header4: "공유링크",
          header5: "파일 위치",
          options: "파일옵션",
        },
      },
      tooltip: {
        tutorial: "튜토리얼",
        helpcenter: "헬프센터",
      },
      tab: {
        link: "공유링크",
        file: "파일",
      },
      none_plan: {
        landing: {
          top: "유료 플랜이 없어 Strong Box 생성이 불가합니다.",
          top_with_sharelink: "유료 플랜이 없어 공유링크 생성이 불가합니다.",
          bottom: "하지만 튜토리얼을 제공해 드립니다.",
        },
        show_button: "튜토리얼 시작하기",
      },
      favorite: {
        title: "즐겨찾는 Strong Box",
        empty: "즐겨찾는 Strong Box가 없습니다.",
      },
      file: {
        recent: "최근 파일",
        empty: "최근 파일이 없습니다.",
      },
      list: {
        title: "전체 Strong Box",
        empty_title: "Strong Box 생성 버튼을 통해 생성할 수 있습니다.",
        empty_title_highlight: "Strong Box 생성",
      },
      create: "Strong Box 생성",
      create_mobile: "생성",
    },
    strong_box_write: {
      create: {
        title: "Strong Box 생성",
      },
      edit: {
        title: "Strong Box 수정",
      },
      info: {
        title: "Strong Box 정보",
      },
      default_info: "기본 정보",
      name_form: {
        label: "Strong Box 이름",
        placeholder: "Strong Box 이름을 입력해주세요.",
        helper_text:
          "Strong Box 이름은 필수 입력 사항입니다. 잘 모른다면 가제로 적어두고 원하실 때 수정 가능합니다.",
        helper_text_error: "글자 수를 초과하였습니다.",
      },
      create_at: "Strong Box 생성일",
      setting: {
        title: "Strong Box 설정",
        change_owner: "소유권 변경",
        owner_msg: (name: string) =>
          `${name}님은 해당 Strong Box의 소유자 입니다.`,
      },
      member: {
        title: "멤버관리",
      },
      remove: {
        title: "Strong Box 삭제",
        alert: "Strong Box 삭제 주의 사항",
        alert_msg: "삭제 시 작성된 모든 파일은 영원히 복구 불가능합니다.",
        button: "삭제",
        modal_msg: (name: string) => `${name}을(를) 삭제하시겠습니까?`,
      },
      cta: {
        cancel: "취소",
        create: "생성",
        edit: "수정",
      },
    },
    document_box_inside: {
      nda: "NDA 관리",
      folder: {
        title: "폴더",
        create: "폴더 생성",
        none_folder: "생성된 폴더가 없습니다.",
      },
      file: {
        title: "파일",
        upload: "파일 업로드",
        bold_01: "마우스로 드래그",
        bold_02: "파일 업로드 버튼 ",
        or: "또는",
        regular_01: "해서 파일을 추가할 수 있습니다.",
        regular_02: "을 통해 업로드할 수 있습니다.",
      },
    },
    nda_management: {
      title: "NDA 관리",
      create_template: "템플릿 생성",
      tabs: {
        template: "NDA 템플릿",
        signed: "체결된 NDA",
      },
      placeholders: {
        template: "NDA 파일명을 입력해주세요.",
        signed: "NDA 템플릿명을 입력해주세요.",
      },
      template: {
        empty: "NDA 템플릿이 존재하지 않습니다.",
      },
      signed_nda: {
        empty: "체결된 NDA가 없습니다.",
        open: "보기",
      },
    },
    nda_template_inside: {
      title: "NDA 템플릿 상세",
      open: "템플릿 열기",
      info: {
        creator: "생성자",
        date: "생성일",
      },
      tabs: {
        signed: "서명 내역",
        share_link: "연결된 링크",
      },
      signed_empty: "서명 내역이 존재하지 않습니다.",
      link_empty: "연결된 공유링크가 존재하지 않습니다.",
    },
    personal_information_modal: {
      submit_button: (ndaId?: string) => (ndaId ? "다음" : "동의 후 제출"),
      policy_button: "전문보기",
      select_option1: "개인정보 처리 방침에 동의합니다.",
      select_option2: "사이트 쿠키 정책에 동의합니다.",
      description1: "문서 보안을 위하여 해당 파일은",
      description2: "이메일 정보를 입력해야만 확인하실 수 있습니다.",
      description3:
        "개인 정보 처리 방침을 확인하시고, 동의 후 이메일 정보를 입력해주세요",
      label_name: "이름",
      label_email: "이메일",
      placeholder_name: "이름을 입력해주세요.",
      placeholder_email: "이메일 주소를 입력해주세요.",
      title: "개인 정보를 입력해 주세요.",
      sub_title:
        "문서 보안을 위하여 해당 파일은 보안 정책에 \n 동의 후 열람하실 수 있습니다.",
    },
    policy_agreement: {
      submit_button: "정책 동의 및 서명 제출하기",
      select_option1: "전자 서명 및 전자 문서의 법적 효력에 대해 동의합니다.",
      select_option2:
        "서명 완료 후 발송되는 문서에 대해 원본으로 발송됨에 동의합니다.",
      select_option3: "GOONO 전자서명 이용약관에 동의합니다.",
      description1: "GOONO는 아래의 전자 서명 관련 정책을 취하고 있습니다.",
      description2: `“정책 동의 및 서명 제출하기” 버튼을 클릭하시면,`,
      highlight_text: "“정책 동의 및 서명 제출하기” 버튼",
      description3:
        "자동으로 서명 정책에 동의하며 전자 서명 법적 효력에 동의함을 알립니다.",
      button: "NDA 계약서 보기",
      title: "문서 열람 정책에 동의해주세요.",
      sub_title:
        "문서 보안을 위하여 해당 파일은 보안 정책에 의해 동의 후 \n 열람하실 수 있습니다. 확인 후 동의하시면 아래에 서명을 해주세요.",
    },
    reply_email: {
      title: "공유링크 이메일 발송",
      submit: "발송",
      submit_hidden: "이메일 ",
      title_email: "이메일 메세지",
      title_member: "받는사람",
      title_preview: "미리보기",
      sub_title_email:
        "메일에 입력할 메세지를 입력해주세요. 이메일 미리보기에서 실시간으로 확인할 수 있습니다.",
      placeholder: "(필수) 작성할 내용을 입력해주세요.",
      add_member: "추가",
      preview_sub_title: "파일 공유",
      preview_description: (userName: string, fileName: string) =>
        `${userName}이 ${fileName} 파일을 공유했습니다. \n 아래 버튼을 클릭하여 파일을 확인하실 수 있습니다.`,
      open_file: "파일 보기",
      preview_info: "*본 메일은 발신 전용으로, 회신이 불가능합니다.",
      location: "대전광역시 유성구 어은로 44, 2층",
    },
    document_inside: {
      title: "파일상세",
      button: {
        delete_document: "삭제",
        open_document: "파일 열기",
        create_document: "공유 링크 생성",
      },
      info: {
        creator: "생성자",
        created_at: "생성일",
      },
      tab: {
        share_link: "공유 링크",
        visitor: "방문자",
        insights: "인사이트",
      },
      empty_data: {
        share_link: "공유링크가 존재하지 않습니다.",
        visitor: "방문자가 없습니다.",
        insights: "조회된 페이지별 체류시간 데이터가 없습니다.",
      },
      table: {
        share_link: {
          header1: "활성",
          header2: "이름",
          header3: "유효 기간",
          header4: "공유 링크",
          header5: "링크 옵션",
          header6: "삭제",
        },
        visitor: {
          header1: "이름",
          header2: "이메일",
          header3: "방문",
          header4: "다운로드",
          header5: "체류시간",
        },
      },
      insights_info: {
        visitors: "총 방문자",
        time_spent: "평균 체류 시간",
        downloads: "다운로드 수",
        link: "최다 방문 링크",
      },
      share_link_expired: "기간만료",
    },
    insights_graph: {
      label: "체류 시간",
      ticks: {
        min: "분",
        second: "초",
      },
    },
    nda_custom: {
      tooltip: "서명자가 문서에 내용을 입력해야 할 위치에 놓아주세요.",
      sign_user: "서명자",
      sign: "서명",
      text: "텍스트",
      back_btn: "나가기",
      create: "공유링크 생성",
    },
  },
  en: {
    new_service_linkage: {
      title: "Integrations",
      sub_title: "Integrations list",
      sub_title_guid: (name?: string) =>
        `List of Repository Interlocked by ${name}`,
      sub_title_guid_empty: (name?: string) =>
        `There is no repository list linked by ${name}`,
      linkage: "Add repository",
      desc: {
        title: `Integrat research notes to the “Repository”`,
        p1: "Click the",
        p1_2: "at the top right in the note.",
        p2: "Every night at 12:00 p.m., the daily Commit history, comments, and issues are automatically added.",
        p2_highlight: "Every night at 12:00 p.m.,",
        p3: "Authentication is automatically performed.",
        p3_highlight: "Authentication",
      },
      empty: "No Interlocked research notes, research folders.",
      unlinkage: "Disconnect",
      guide_btn: "Service integration manual",
      free_plan_desc:
        "Github integration is available to only users who paid plan.",
    },
    github_linkage: {
      title: "Add repository",
      step1: "Select note",
      step1_guid: "Select a research note to Integrat the repository.",
      note_search: "Search for note name",
      all: "All",
      owner_writer: "Owner / Write only",
      step2: "Select folder",
      step2_guide:
        "If not selected, it will be Interlocked to the research note home.",
      note_home: "Research note home",
    },
    landing_help: {
      title: "Frequently Asked Questions",
      subtitle_1: "Curious about something before using GOONO?",
      subtitle_2: "GOONO Team will help you with any other questions.",
      contact_btn: "Request",
      qna: {
        q_1: {
          q: "It has been a while since the project selection. Is it ok to write research note from now on?",
          a_1: "You can always upload research files as long as the project hasn’t been terminated.",
          a_2: "The previous records can be uploaded as images,",
          a_3: "PDF, DOCS, EXLS, PPTX, etc.,",
          a_4: "and can be individually set",
          a_5: "for filing date.",
          a_6: "",
          a_7: "",
          a_8: "(But, timepoint verification will be done as the uploaded timepoint, and will not be a problem if the project hasn’t been terminated.)",
          a_9: "Also, the research note will automatically satisfy the legal terms at the point of upload, and will be legally effective as a research note.",
          a_10: "If your notes are handwritten, please store the original files separately even after uploading them through GOONO for unexpected circumstances.",
        },
        q_2: {
          q: "Can GOONO be acknowledged as research note for R&D projects?",
          a_1: "GOONO is a service",
          a_2: "selected by ",
          a_3: "the Small and Medium Venture Business Department ",
          a_4: "as the electronic research note supplier.",
          a_5: "",
          a_6: "",
          a_7: "Also, GOONO satisfies",
          a_8: "the related laws listed below for research note, ",
          a_9: "so it can be used for proof ",
          a_10: "with other projects as well.",
          a_11: `All research notes filed through GOONO are legally acknowledged, and can be used in various fields as legal evidence such as government R&D business and patent application, technology transfer, protection of intellectual property, etc.`,
          a_12: "[Legal requirements for electronic research note]",
          a_13: "Signature verification for writer and checker(the checker’s signature can be unnecessary for certain cases)",
          a_14: "Automatic record function for research record filing date and time.",
          a_15: "Function that can detect any forgery/tampering regarding the records.",
        },
        q_3: {
          q: "How can we process the payments?",
          a_1: `GOONO can use the research funds as project payments.`,
          a_2: "All payments due to the use of GOONO can be dealt with direct and indirect expenses, thus lowering the cost burden.",
          a_3: "Research activity cost(direct expense)",
          a_4: "Research equipment and materials cost – Computational process and management costs(direct expense)",
          a_5: "Performance support cost (indirect expense) ",
          a_6: ` Intellectual property rights application/registration cost 
          All costs necessary for application/registration/preservation of intellectual property
           rights related with national R&D projects performed by institutes 
           for the year, or Costs necessary for technology transfer 
           such as technology value assessment, 
           Costs necessary for standardization(including certification) activity 
           such as domestic/abroad standard registration, 
           and cases related to in-house regulation enactment/operation 
           for research note management, research note education/recognition activity 
           and research note activation`,
        },
        q_4: {
          q: "Are records written on post-its or whiteboard really acknowledged as research notes?",
          a_1: `If it is uploaded through GOONO, all records including images and office document files are acknowledged as research notes.`,
          a_2: "Not only the records",
          a_3: "written on post-its or whiteboards,",
          a_4: "but other formats such as pictures on tablet PC ",
          a_5: "can also be uploaded without restriction ",
          a_6: `and used as electronic research notes.`,
        },
        q_5: {
          q: "If there are 5 participants for a project, do they all have to be registered as members?",
          a_1: `It is ok for the administrative researcher to write and submit all research notes. `,
          a_2: `In principle, research notes are the means to prove each participant’s research activities, so it is best to register individually and write your own research note.`,
          a_3: `If an administrative researcher uploads all research notes, the project participation will only be acknowledged for the administrative researcher, and there might be issues regarding research participation later on, also along with difficulties of proving the participation of all researchers.. `,
          a_4: "So, ",
          a_5: "we do advise you to distribute premium plan to all participating researchers ",
          a_6: `and prove individual activities for your research work.`,
        },
        q_6: {
          q: "Research notes created by GOONO don’t have the signature line for the project manager or confirmer. Is this ok?",
          a_1: `The confirmer’s signature on `,
          a_2: `GOONO’s electronic research note is `,
          a_3: `handled by the system’s server as a third party. `,
          a_4: `This certification is applied with blockchain`,
          a_5: ` and approval of Korea Intellectual Property Strategy Agency and Korea Electronic Certification.`,
          a_6: `Also, the information of project manager can be added to individual projects, so that it can be used selectively.`,
        },
        q_7: {
          q: "Is the service safe from external leakage or hacking?",
          a_1: `All documents on GOONO`,
          a_2: `are encrypted with private block chain system, `,
          a_3: `and are strongly protected`,
          a_4: ` by GOONO’s own server. `,
          a_5: `GOONO’s own server has double encryption, `,
          a_6: `which allows only the hash values of the research notes to be stored on network, `,
          a_7: "thus rendering the browsing of individual content files impossible even for the company. ",
          a_8: `The users don’t need to worry about data leakage or hacking.`,
        },
        q_8: {
          q: "Are the notes deleted when the premium plan period terminates?",
          a_1: `The posts uploaded during the validation of premium plan`,
          a_2: ` are kept safely after the premium plan termination. `,
          a_3: `But, when the premium plan has been terminated, `,
          a_4: `there will be restriction `,
          a_5: "on services of paid premium plan,",
          a_6: `and the use of watermark creation, folder creation, `,
          a_7: `folder sharing, OCR search, `,
          a_8: "external service linkage, etc. ",
          a_9: `will not be possible.`,
          a_10: "",
        },
      },
    },
    loading_screen: {
      loading: "Loading data...",
    },
    admin: {
      tabs: {
        insight: {
          title: "Reports",
          subTitle: "You can check the report of users's research notes.",
          sort: {
            title: `Sort research files`,
            sort_by_last_created: "Last Created",
            sort_by_first_created: "First Created",
            sort_by_ascending_name: "Name Ascenindg Order",
            sort_by_descending_name: "Name Descending Order",
          },
        },
      },
    },
    workspace_setting: {
      screen_title: "Workspace settings",
      tab: {
        overview: "Overview",
        member: "Members",
        security: "Security",
      },
      current_plan: {
        title: "Plan",
        period: "Plan period",
        member: "Plan members",
      },
      overview: {
        title: "Settings",
        leave_btn: "Leave",
        leave_desc:
          "Any notes that I have created within the workspace, automatically become the property of the workspace owner.",
        delete_btn: "Delete",
        save_btn: "Save",
        extenstion: "Uploadable extensions : png, jpg, jpeg",
        form_label: "Workspace name",
        form_placeholder: "Please enter a workspace name.",
        form_helpertext:
          "Please provide a name for your organization or company. The name should not exceed 100 characters.",
        form_helpertext_error: "Please enter at least 1 character.",
      },
      member: {
        title: "Management workspace members",
        email_invite: "Email invitation",

        max_member: {
          title:
            "The maximum capacity of members invited under this plan has been reached.",
          sub_title:
            "You can add more members as needed through aditional payment.",
          confirm: "Contact us",
        },
        transfer_owner: "Transfer ownership",
      },
      info: {
        level: {
          title: "User Rank",
          labels: {
            member: "Member",
            admin: "Admin",
          },
        },
        period: "Plan Period",
        researchers: "Workspace Member",
      },
      banner: {
        extension: "Plan Extension",
        more_members: {
          title: "The member capacity is full for invitations using this plan.",
          sub_title:
            "You can add additional workspace through an additional payment to accommodate the desired number of researchers.",
          button_text: "Adding members",
        },
      },
      sub_title: {
        admin_version: "Member Management",
        member_version: "Workspace member",
      },
      invite: {
        link_text: "Share Link",
        copy_button: "Copy",
        select_box_text: {
          member: "Member",
          admin: "Admin",
        },
        submit_button: "Invite",
        input_placeholder:
          "A workspace invitation email will be sent to the email you provided.",
        guide_text: "You can designate up to 3 administrators.",
        helper_text: {
          email_format: "Please enter in email format.",
          already_hold: "This is a user already existing within the workspace.",
        },
      },
      table: {
        input_placeholder: "Search here",
        remove_researcher_button: "Delete member",
        add_member_button: `Add as workspace member`,
        manage_external_researcher: `Manaage dormant accounts`,
        pending_status_banner: {
          time_left: {
            title:
              "You can participate in workspace activities by signing up with this email address.",
            button_text: "Cancel",
          },
          expired_time: {
            title: "The email invitation has expired.",
            button_text_1: "Resend",
            button_text_2: "Cancel",
          },
        },
        header: {
          name: "Name",
          role: "Permission",
          email: "E-mail",
          affiliationAndOrganization: "Affiliation/Organization",
          majorAndDepartment: "Major/Department",
          signature: "Sign",
          authentication: "Authentication",
        },
        body: {
          role: {
            owner: "Owner",
            admin: "Admin",
            member: "Member",
            other_member: "Dormant Account",
          },
          sign_info: {
            is_registration: "Registered",
            is_not_registration: "Unregistered",
          },
        },
        select_mode: {
          download_document_button: "Download Transfer onwership",
        },
      },
      cta: {
        title: {
          default:
            "Administrators can’t delete, so please change the permissions to researcher and then proceed with the deletion.",
          selected_user: (count: number) =>
            `Are you sure to delete the ${count} members?`,
          remove_member: "Please select the member to delete",
          transfer_owner: "Please select 1 member to transfer ownership.",
        },
        button: {
          cancel: "Cancel",
          select_all: "Select all",
          remove: "Delete",
        },
      },
      popover: {
        title: "set permission",
        options: {
          admin: "Admin",
          member: "Member",
        },
      },
    },
    workspace_empty: {
      title: "There is no workspace yet.",
      sub_title_01: "It seems like a workspace hasn't been created yet.",
      sub_title_02: "Would you like to create a new workspace now?",
      create: "Create",
    },
    auth_progress: {
      title: "Blockchain Collection",
      folder_to_save: "Folder to Save",
      electronic_signature: "Electric Signature",
      hash: "Hash",
      auth: "Certified",
      subtitle1:
        "All uploaded files are encrypted on the blockchain and stored securely.",
      subtitle2:
        "Get certified at the time of creation to create a definite legal effect, and search for text in the picture through OCR technology!",
      empty_desc1: "Create your own blockchain",
      empty_desc2: "By adding research notes!",
      footer: {
        username_suffix: "'s",
        file_count_suffix: " files ",
        desc: `\n are safely stored in the blockchain.`,
      },
      block_content_description: (blockNum: string) =>
        `<b>${blockNum}th<b> of all blocks of Goono service certified`,
      nth_block: (blockNum: string) => `${blockNum}th certified block`,
      certified_at: (timestamp: string) => `Certficated ${timestamp}`,
      no_certified: {
        title: "No certified blockchain.",
        desc_01: "Add new files",
        desc_02: "on the research notes tab!",
      },
    },
    main: {
      guest_title: "Start GOONO",
      plan_title: "My Plan",
      plan_card: {
        name: "Plan name",
        period: "Plan period",
        member: "Plan members",
      },
      upcoming: "Upcoming",
      add_note_msg: "Drag files from My Computer.",
      sub_text:
        "jpg, jpeg, png, svg, tiff, webp, heic, doc, docx, ppt, pptx, pdf, xls, xlsx are available.",
      summary: "Dashboard",
      strong_box_home: "Strong Box Home",
      goono_start: "Start GOONO",
      activity_button: "Activity",
      bookmark_notes: "Bookmarks",
      empty_bookmark_notes: "There are no bookmark notes.",
      view_all: "Details",
      weekly_report: "Weekly report",
      file_upload_report: "Added research files",
      most_likely_tags: "Frequently used tag(#) TOP5",
      tags_empty_1: "No tags on this week.",
      tags_empty_2: "Try make your own!",
      active_researchers: "Weekly active members",
      goono_guide: "GOONO user's guide",
      data_room_guide: "Help center",
      view_faq: "FAQ",
      amount: "minutes",
      guide: {
        total_guide_title_01: "Introduction about GOONO function",
        total_guide_title_01_highlight: "GOONO function",
        total_guide_title_02: "Download GOONO User's Manual",
        data_room_guide_title_01: "Discover what data room can do!",
        data_room_guide_title_01_highlight: "data room can do!",
        data_room_guide_paid_question: `Pricing for every team`,
        data_room_guide_paid_question_highlight: `Pricing`,
        data_room_guide_paid_question_button: `Pricing`,
        total_guide_title_02_highlight: "GOONO User's Manual",
        total_guide_subtitle:
          "전체 기능에 대한 안내를 한번에 볼 수 있는 노션 링크와 ",
        total_guide_subtitle_02: "기능 소개에 대한 영상 링크입니다.",
        pdf_download: "Download guide(PDF)",
        notion_guide: "Notion guide",
        video_link: `Video guide`,
        start_name: "Let's start GOONO . KR",
        start_caption: "Overview of GOONO service",
        part1_name: "Note create / Management . KR",
        part1_caption: "#Create note#Members#Modify note",
        part2_name: "Research file / folder Management . KR",
        part2_caption: "#Add files#Delete files#Create folder#Modify folder",
        part3_name: "Intergrations . KR",
        part3_caption: "#Link GitHub to Research note#GitHub list",
        part4_name: "Complete research notes . KR",
        part4_caption: "#Download Research Note PDF",
        part5_name: "Check research note activity history",
        part5_caption: "#Activity #Cooperation",
        sample_project_title: "Sample Note",
        sample_project_subtitle_01: "Check example note!",
        open_sample_button: "FAQ",
        help_center_desc: (_type: "goono" | "dataRoom") =>
          `Have a feature request?`,
      },
      recent_history: {
        title: "Recent history",
        alert: "20 most recent records",
        upload_btn: (windowWidth: number) =>
          `${windowWidth > 600 ? "Recent uploads" : "Uploads"}`,
        download_btn: (windowWidth: number) =>
          `${windowWidth > 600 ? "Recent downloads" : "Downloads"}`,
        created_share_link_btn: (windowWidth: number) =>
          `${windowWidth > 600 ? "Recent custom links" : "Links"}`,
        no_upload: "No recent files",
        no_link: "No recent links",
        no_download: "No recently downloaded files",
      },
      expired_plan: {
        title: "Expired",
        desc: "If you want to extend the period, Please contact us.",
      },
      free_plan_guard: {
        title: "Plan purchase inquiry",
        desc: (_type: "goono" | "dataRoom") =>
          `No plan you paid. If you want to purchase a plan, contact us, please.`,
      },
      free_plan_banner: {
        title: "Sign up for a free trial to access all features for 14 days!",
        button: "Try for free",

        research_note: {
          title_01: "Unlimited File Upload",
          title_02: "Collaborative Team Research",
          title_03: "Get PDFs with a Timestamp Certification",
          title_04: "Analytic Dashboard for Research Activities",
          desc_01: "Upload and store your files securely on the blockchain.",
          desc_02: "Enhance your team's performance by sharing your research.",
          desc_03:
            "Top-tier e-Certification with Korea Electronic Certification Authority.",
          desc_04: "Easily manage members and data in admin dashboard.",
        },
        data_room: {
          title_01: "Unlimited File Upload",
          title_02: "Attach an NDA e−Signature",
          title_03: "Create Shared Links",
          title_04: "See Real-time Insights",
          desc_01: "Upload and safeguard your files on the blockchain.",
          desc_02: "Easy NDA to ensure confidentiality throughout project.",
          desc_03: "Securely share your documents with custom links.",
          desc_04:
            "Identify your company's strengths for your upcoming fundraisings.",
        },
      },
      data_room: {
        tutorial_card: {
          title: "See how data room works in action",
          button: "Start tutorial",
        },
      },
    },
    my_page: {
      title: "Mypage",
      change_image: {
        top: "Change",
        bottom: "image",
      },
      name: "Name",
      email: "Email",
      school: "Institution",
      department: "Major",
      sign: "Sign",
      required: "Required",
      edit_profile: "Edit profile",
      edit_signature: "Edit signature",
      modify_password: "Change password",

      cancel: "Cancel",
      reset: "Reset",
      save: "Save",
      fail: "Failed to change user information.",
      loading: "Change user information..",
      success: "Successfully changed.",
    },
    ip_block: {
      sub_title: "IP Blocking",
      title_01:
        "The IP address you are currently using is not permitted to access this workspace.",
      title_02: "Please select another workspace.",
      title_03: "Please contact the workspace manager.",
      go: "Go",
      create: "Create",
    },
    workspace_create: {
      title: "New workspace",
      confirm: "Create",
      back: "GOONO Home",
    },
    payment_result: {
      due: "Period : ",
      workspace_seats: "Capacity",
      card: {
        text: "Payment is complete!",
      },
      info_text: "You can use all functions without limit.",
    },
    payment_V_bank: {
      user_info: {
        title: "Payment Information",
        body: "Premium plan",
        badge: "Waiting for Deposit",
        account: "Deposit Account",
        amount: "Deposit Amount",
      },
      bankbook: {
        text1: "Deposit without passbook ",
        text2: "is requested!",
        text3: "You can use all function without limit after payment.",
      },
    },
    project_inside: {
      title: "Research note details",
      title_sub: "Files",
      empty_note: "There are no added research files.",
      research_file: {
        more_tags: (count: number) => `Additional ${count}tag(s)`,
        remove: "Remove file",
        file_info: {
          conver_pdf: "Converting to PDF",
          create_thumb: "Creating a preview",
          fail_err: "Failed to add research file",
          thumb_err: {
            p1: `Unable to`,
            p2: `create preview`,
          },
        },
      },
      file_alarm: {
        title: `Update Notification`,
        description: `The file names have been added to the list.
        For previously uploaded files, the file name was temporarily set as a hash value.
        Please change the file name.`,
      },
      date_picker: {
        sun: `Su`,
        mon: `Mo`,
        tue: `Tu`,
        wed: `We`,
        thu: `Th`,
        fri: `Fr`,
        sat: `Sa`,
        select_end_date: `Please select an end date.`,
      },
      tag_ocr_search: {
        title: `Tag search`,
        guide_text: `Try searching for tags`,
        recently_tag: `Recently used tags`,
        related_tag: `Related tag`,
        no_data: `No tag has been detected.`,
        search_tag: {
          start: ``,
          end: ` tag search`,
        },
      },
      unknown_project_dialog: {
        title: "That link is invalid.",
        body_msg:
          "Sorry,\nThe path you are currently on has been deleted and cannot be accessed",
        button_msg: "Confirm",
      },
      unknown_project_member_dialog: {
        title: "You do not have access on research note.",
        body_msg:
          "Sorry, You do not have permission on research note.\nPlease contact the owner of the research note.",
        button_msg: "Confirm",
      },
      CTA: {
        download_button: "Download note",
        download: (folder_cnt: number, file_cnt: number) =>
          `Selected folders: ${folder_cnt}, Selected files: ${file_cnt}`,
        delete: (file_cnt: number) =>
          `Are you sure to delete ${file_cnt} selected research files?`,
        move: (file_cnt: number) =>
          `Are you sure to move ${file_cnt}selected research files?`,
      },
      button: {
        modify_note: "Modify Note",
        log_history: "Activity",
        download: "Download note",
      },
      label: {
        manager: "Manager",
        number: "No.",
        period: "Period",
        files: "Files",
      },
      search_popover: {
        none_result: `No history has been detected.`,
        title: "Search research note",
        placeholder: "Search by the note name.",
        all: "Notes ",
        share: (data: number) => `Share ${data} members`,
        select_limit_dialog: {
          title: "Unable to select add notes",
          body_msg: (limit_count: number) =>
            `Sorry.\nYou can only select up to ${limit_count} study notes from the admin page.`,
          button_msg: "Confirm",
        },
      },
      filter: {
        all_member: "Member",
        recent_authenticate: "Recently authenticated",
        first_authenticate: "First authenticated",
        recent_written: "Recently written",
        first_written: "First written",
        move_note: "Move files",
        delete_note: "Delete files",
        writer: "Writer",
        search_member: "Search by member",
        search_member_placeholder: "Search by name, email",
        file_type: "File type",
        file_name: "Name",
        search_file_type: "Search by file type",
        search_tag: "Tag search",
        search_ocr: "OCR search",
        related_tag: "Related tag",
        no_related_tag: "No recently used tags",
        file_title: "Search for research files",
        period: "Written At",
        no_period: "No period is selected.",
        search: "Search",
        add_keyword: "Add",
        init_search: "Reset",
        success_search: "Search options have changed.",
        detail_search: "Detailed search",
        detail_search_result: (file: number) =>
          `${file} research files detected.`,
        clear: "Clear",
        total_period: "Total period",
        add_file: "Add files",
      },
      table_cell: {
        file_info: "File info",
        format: "Format",
        written: "written",
        authentication: "authentication",
        author: "author",
        add_fail: "Fail to add.",
        converting_pdf: "Converting to PDF..",
        creating_preview: "Creating preview..",
        delete_button: "Delete",
      },
      dnd_box: {
        title: "Add research files by drag & drop",
        allow_file_info:
          "Addable file types : Jpeg, Jpg, Png, SVG , tiff , Webp , Heif , Heic , Doc , Docx, PPTX , PPT , Xls , Xlsx , PDF",
      },
      note_more: {
        title: "File options",
      },

      select_note: "Select the note for certification.",
      download_note: "Select the note to download.",
      delete_note: "Select the note to delete.",
      bin_folder: "Delete All",
      alert: {
        title: "Delete all notes of ",
        body: "Are you sure you want to delete all notes in this folder?",
      },
      delete_note_alert:
        "Are you sure you want to delete a note in this folder? ",
      delete_notes_alert: {
        title: "Delete Notes",
        msg1: "Are you sure you want to delete ",
        msg2: "notes in this folder?",
      },
      user_count: "members",
      user_count_postfix: "",
      loading: "File Syncing..",
      share: {
        create_link: "Creating a share link",
      },
      folder: {
        folder_list: "Research folder list",
        create: "New folder",
        path_home: "Home",
        option_title: "Folder options",
        edit_name: "Modify name",
        github_link: "Integration",
        github_link_delete: "Disconnect",
        delete: "Delete",
      },
      folder_grid: {
        folder: "Research folder",
        empty_folder: "No folders were created.",
        more_folder: (data: number) => `See more ${data} folders`,
        fold: "fold",
        guide: "You can create up to 30 research folders.",
      },
      drawer: {
        title: {
          note: "History by note",
          project: "Detailed history",
          download: "Download history",
        },
        all_activities: "All activities",
        all: "All",
        activity: (num: number) => (num > 1 ? "Activities" : "Activity"),
        options: "Search period",
        activity_update_open: "Details",
        activity_update_close: "Close",
        member_options: {
          all: "All",
          personal: "Only me",
        },
        activity_options: {
          create: "Create",
          modify: "Modify",
          delete: "Delete",
          download: "Download",
          upload: "Add file",
          move: "Move",
          select_all: "Select all activities",
          unselect_all: "Unselect all activities",
        },
        activity_modify_options: {
          project_name: "Research name",
          project_number: "No.",
          project_manager: "Manager",
          delete_option: "Prevent deletion",
          download_option: "Prevent download",
          date: "Period",
          owner: "Change an owner",
          member_invite: "Invite a member",
          member_delete: "Delete a member",
          move_note: "Research note",
          move_other_project: "Other research note",
          allow_delete: "Allow deletion of folders and files",
          prevent_delete: "Prevent deletion of folders and files",
          allow_download: "Allow research file downloads",
          prevent_download: "Prevent research file downloads",
        },
        num: "",
        period: "Search by period",
        period_options: {
          all: "All period",
          one_week: "Recent 1 week",
          one_month: "Recent 1 month",
          three_month: "Recent 3 months",
          custom_period: "Customizing",
          total_project_period: "Total period",
          all_period: "All period",
        },
        date_pick: "Select search period",
        date: {
          today: "Today",
          mon: "Mon",
          tue: "Tue",
          wed: "Wed",
          thu: "Thu",
          fri: "Fri",
          sat: "Sat",
          sun: "Sun",
        },
        member: (num: number) => (num > 1 ? "members" : "member"),
        person_num: "",
        all_members: "All members",
        search_members: "Search",
        members_placeholder: "Search by name or email.",
        select_all_members: "Select all members",
        unselect_all_members: "Unselect all members",
        start: "START",
        end: "END",
        no_date: "No info",
        clear: "Clear",
        no_result: "No history within the period.",
        log: {
          to_folder: "",
          to_folder_2: "",
          to_note_kr: "",
          from: (folder_name?: string) =>
            `${folder_name !== undefined ? "folder" : ""} to`,
          project: "연구노트의",
          upload: (num: number) => `${num} file(s) have been added.`,
          delete: (num: number) => `${num} file(s) have been deleted.`,
          download: (num: number) =>
            `Downloaded ${num} file(s) as a research note`,
          originDownload: (num: number) =>
            `Downloaded ${num} original file as a research note`,
          originDownlaodHighlight: (num: number) => `${num} original file`,
          upload_highlight: (num: number) => `${num} file(s) have been added.`,
          delete_highlight: (num: number) =>
            `${num} file(s) have been deleted.`,
          download_highlight: (num: number) =>
            `Downloaded ${num} file(s) as a research note`,
          delete_folder: (num: number) =>
            num > 0
              ? `folder,  ${num} file(s) have been deleted`
              : `folder have been deleted`,
          delete_folder_highlight: (num: number) =>
            num > 0
              ? `folder,  ${num} file(s) have been deleted`
              : `folder have been deleted`,
          create_comment: (num: number) => `file has a comment on page ${num}.`,
          create_comment_highlight: (num: number) => `comment on page ${num}.`,
          create_comment_all: "file has a comment on all page.",
          create_comment_all_highlight: "comment on all page.",
          create_folder: " new folder was created",
          create_folder_highlight: "f older was created",
          update_folder: " modified",
          update_folder_highlight: "modified",
          update_file: " modified",
          update_file_highlight: "modified",
          from_en: "From",
          from_kr: "",
          prefix_kr: "",
          git_link: (folder_name?: string) =>
            `${folder_name !== undefined ? "folder" : ""} Intergrated with`,
          upload_github: "repository, a file has been added",
          upload_github_highlight: "repository, a file has been added",
          move: (num: number) => `${num} file(s) have been moved.`,
          move_highlight: (num: number) => `${num} file(s) have been moved.`,
          create_project: "New research note was created",
          create_project_highlihght: "note was created",
          transfer_project_owner: (owner: string) =>
            `research note owner, changed to '${owner}'`,
          transfer_project_owner_highlight: (owner: string) =>
            `changed to '${owner}'`,
          delete_project: "research note was deleted",
          delete_project_highlight: "note was deleted",
          invite_member: (member: string) => `Member '${member}' was invited`,
          invite_member_highlight: (member: string) =>
            `'${member}' was invited`,
          delete_member: (member: string) => `Member '${member}' was deleted`,
          delete_member_highlight: (member: string) =>
            `'${member}' was deleted`,
          update_project_info: "Information modified",
          update_project_info_highlight: "Information modified",
          update_project_option: "Setting modified",
          update_project_option_highlight: "Setting modified",
        },
        home: "Home",
        cancel: "Cancel",
        submit: "Submit",
        search: "Search",
      },
      breadcrumb: {
        create_folder: (_width: number) => "New Folder",
        edit_folder: "Modify name",
      },
      initial: {
        title: "Start research note",
        sub_title:
          "Congratulations on creating a new research note.\n Manage your research records by adding research files or creating folders. ",
        add_file: "Add files",
        add_file_guide:
          "Research files can be a added through Drag & Drop or OS File Explorer.",
        new_folder: "New folder",
        new_folder_guide: "Use folders to manage research files efficiently.",
        link_github: "Github",
        link_github_guide:
          "Commit history, comments, and issues for the selected repository are added to the research file at 12:00 every night.",
      },
      sample_note: {
        description:
          "The sample note is a temporary research note created to assist understanding. Some editing functions are available for the content of this research note.",
      },
    },
    upload_screen: {
      title_upload: "Add research files",
      subtitle_upload: "Add research files List",
      guidetext_upload:
        "You can change the order of the list through Drag&Drop",
      written_title: "Written on",
      written_guide:
        "Please select the date you recorded or documented after the research",
      authentication_title: "Authentication",
      authentication_guide:
        "Authentication is provided as add file time and cannot be modified. (A point-in-time certification mark is given.)",
      authentication_guide_highlight: "cannot be modified.",
      note_title: "Research note",
      note_guide: "Please select a research note to add a research file.",
      all: "All",
      folder_title: "Research folder",
      folder_guide:
        "If not selected, it will be uploaded to the note home. Please select a folder.",
      sorting: "File sorting",
      google_drive: "Google drive",
      title_google: "Add Google Drive files",
      subtitle_google: "Google Drive file list",
      alert_google:
        "Google Docs files (documents, spreadsheets, presentations) can only be added as research files under 10mb, and typical files on Google drives can only be added to jpg, jpeg, png, svg, tiff, webp, heic, doc, docx, ppt, pptx, pdf, xls, xlsx format files under 50mb.",
      guidetext_google:
        "Google Docs, Sheets, and Slides, whose documents are smaller than 10MB, can be converted to pdf and imported into GOONO under Google Drive policy.",
      google_placeholder: "Search the file name on Google Drive.",
      google_select: (note: number) => `${note} Selected`,
      google_select_placeholder:
        "Please search for the file name in the selected list.",
      total_list: "Total list",
      selected_file: "Selected files",
      file: "File",
      search_list: "Search files",
      no_searched_file: "No files",
      google_path: "Select google drive path",

      button: {
        logout: "Sign out",
        add: "Add files",
        home: "Home",
      },
      filter: {
        custom: "Custom",
        modify_recent: "Recently <detail>modify<detail>",
        modify_first: "First <detail>modify<detail>",
      },
    },
    preview: {
      preview: "Preview",
      file_detail: "File details",
      file_delete: "Delete file",
      file_download: "Download note",
      origin_file_download: "Download original file",
      file_empty: "No file",
      before_file: "Previous file",
      after_file: "Next file",
      writtenAt: (date: string) => `Written ${date}`,
      comment: "Comment",
      file_info: "File information",
      search_placeholder: "Search with name of research notes.",
      update_written_at_fail: "Failed to change dates",
      update_written_at_success: "Successfully Saved",
      comment_empty: "No Comments",
      comment_placeholder: "Please enter a comment \nYou can use @ to mention",
      comment_error: "Too long. Text length should be less than 1800.",
      comment_add: "Successfully added comment.",
      tooltip: "Read-Only members can't change the 'filed date'. ",
      tag_empty: "No tags",
      edit_tag: "Edit tag",
      add_tag: "Add tag",
      all: "All",
      all_page: "All",
    },
    project: {
      title: "Files",
      edit: "Edit",
      enter: "Enter",
      study_name: "Project Name",
      study_period: "Project Period",
      study_info: "Project Information",
      delete_study_notes: "Delete Notes",
      download_study_notes: "Export as Report",
      no_study_info:
        "There is no project information. If you enter the project information, it will be reflected in the research note PDF.",
      sort_by_last_uploaded: "Last Uploaded",
      sort_by_first_uploaded: "First Uploaded",
      sort_by_last_written: "Last Written",
      sort_by_first_written: "First Written",
      filter_user: "Writer",
      sort_by_first_name: "By ABC",
      sort_by_last_name: "By ZYX",
      sort_by_first_created: "First created",
      sort_by_last_created: "Last created",
      favorite: "Bookmarks",
      default: "All notes",
      grid: {
        more_pinProject: (data: number) => `See more ${data} projects`,
        fold: "Fold",
      },
      create: {
        title: "Create a new research note",
        stepper: {
          step1: "Research info",
          step2: "Note settings",
          step3: "Members",
          step4: "Delete",
        },
        section: {
          step_title1: "Enter research information",
          step_title2: "Note settings",
          step_title3: "Research member",
          step_title4: "Delete research note",
          required: "Required",
        },

        form: {
          project_name_label: "Research name",
          project_name_placeholder: "Enter your research name.",
          project_name_helpertext:
            "It is required. If you don’t know research name, you can enter any text and modify later.",
          project_name_helpertext_error: "You can enter up to 100 characters",
          project_owner_label: "Research manager",
          project_owner_placeholder: "Enter research manger’s name.",
          project_owner_helpertext: "You can enter up to 20 characters.",
          project_owner_helpertext_error:
            "Research manager’s name must be less than 20 characters.",
          project_number_label: "Research number(NO.)",
          project_number_placeholder: "Enter your research number.",
          project_number_helpertext: "You can enter up to 25 characters.",
          project_number_helpertext_error: "You can enter up to 25 characters.",
          date_optoin_3month: "3 months",
          date_option_6month: "6 months",
          date_option_9month: "9 months",
          date_option_year: "1 year",
          date_option_user: "Customizing",
          project_date_label: "Research period",
          project_date_start_label: "Start",
          project_date_end_label: "End",
          project_date_helpertet:
            "You can set the period after selecting start date with the left option.",
          pdf_preview_title:
            "This information will be displayed on research note cover. ",
          pdf_preview_button: "Preview a cover",
          delete_available_label:
            "Prevent research file deletion other than note owner",
          delete_available_helpertext:
            "Research notes cannot be Delete file’s by other/ team member’s than the owner of the note.",
          delete_available_switch_false: "Allow deletion",
          delete_available_switch_true: "Prevent deletion",
          download_available_label:
            "Prevent downloading of research notes (PDF, original) by anyone other than the note owner",
          download_available_helpertext:
            "Downloading research notes (PDF, original) from researchers other than the owner of the research note is not possible",
          download_available_switch_false: "Allow download",
          download_available_switch_true: "Prevent download",
          invite_sub_title:
            "You can invite others by entering their email, or sending invitation link.",
          invite_helpertext:
            "Search by email address, name of researcher Team member.",
          invite_helpertext_edit_screen:
            "Search for team members by e-mail or name.",
          change_owner: "Change an owner",
          owner_note_guide: " is an owner of this note.",
          rw_permissions: "Add research file, Move research file",
          add_file: "Add research file",
          add_file_guide:
            "(You can request file deletion or note donwload to note owner.)",
          view_note: "View research note",
          view_note_guide: "(You can request note donwload to note owner.)",
          delete_remind: "Reminder of research note deletion",
          delete_remind_guide:
            "If you delete it, you can’t restore uploaded research files  and notes. Are you sure to delete?",
          invite_member: "List to invite",
          invite_member_empty: "No researchers to invite.",
          team_member: "Researchers in Team member",
          invite_all_team_member: "All team members are invited",
          permission_option: "Permission",
        },
      },
      sample_note: {
        landing: {
          top: "You can’t create research notes without a premium plan. ",
          bottom:
            "However, we provide a sample research note for demonstration.",
        },
        show_button: "Show sample note",
      },
    },
    purchase: {
      pay: {
        plan: "GOONO Premium Payment",
        month: "mon",
        user: "per",
      },
      title: "Payment Info",
      sub_text: {
        text1: "You choose ",
        text2: "a ",
        text3: "Premium plan",
      },
      method: "Payment Method",
      card: "Credit Card",
      bankbook: "Deposit without Passbook",
    },
    register_email: {
      title: "Sign Up",
    },
    register: {
      title: "Sign Up",
    },
    search_more: {
      title: {
        fileName: "FileName",
        hash: "Hashtag",
        ocr: "OCR",
        folder: "Folder",
      },
      result: "Search Results",
    },
    search_result: {
      result: "Search Results",
      no_result: " No search results.",
      fail_search: {
        text1: "Please check whether the spelling is correct",
        text2: " or search another keyword!",
      },
      fileName: "File name",
      hash: "Hashtag",
      view_all: "All See",
      ocr: "OCR",
      for_prime: "This is the premium user-only function.",
      project: "Research notes",
      folder: "Research folder",
    },
    security: {
      subtitle_1: "Set access IP",
      subtitle_2: "Access Information",
      disable_feature: "Allow GOONO access from any IP addresses.",
      include_admin:
        "Administrators are allowed to access IP addresses without any restrictions.",
      current_ip_addr: "Current IP Address",
      get_ip_addr: "Getting the IP address.",
      label_access: "IP access",
      label_exception: "Exceptional IP",
      allow: "Allow",
      block: "Block",
      add: "Add",
      placeholder_ip: "Please Enter an IP address.",
      delete: "Delete",
      devices: "Devices",
      device_unit: "",
      latest_access_time: "Latest Access Time",
      latest_access_device: "Latest Access Device",
      access_name: "Name",
      access_time: "Timestamp",
      access_ip: "IP Address",
      access_device: "Device",
      access_nation: "Location",
      no_access: "No Access Record",
      acl_error_format: "Invalid Format",
    },
    dashboard_admin: {
      reset_search: "Clear",
      period: "Period",
      week: "This week",
      month: "A month",
      month_6: "6 months",
      select: "Select",
      all: "All",
      close: "Close",
      member_col: "Members",
      member_all: "All Members",
      member_selected: "Selected",
      member_unit: "",
      note_unit: "",
      title: "Notes Overview on workspace",
      select_member: "Select",

      fixed_filter: {
        period: `Period`,
        note: `Note`,
        all_note: `All notes`,
        note_unit: ``,
        researcher: `Member`,
        all_researcher: `All member`,
        researcher_unit: ``,
      },

      tab: {
        overview: "Summary",
        activities: "Activities",
        folder_view: "Folder/Notes on workspace",
        notes: "Research Notes",
      },

      note_list: {
        search_placeholder: `Search by research note name or researcher name.`,
        download_excel: `Download excel`,
        filter_option: {
          title: `Search option`,
          all: `All`,
          note_name: `Project name`,
          admin: `Admin`,
          owner: `Owner`,
        },
      },

      overview: {
        subTitle:
          "The graph of the research file status is activated based on the authentication date.",

        title_1: "Summary",
        description_1: "* These diagram is based on a ",
        description_2: "timestamp",
        description_3: " from blockchian network.",
        graph_1: "New Notes Uploaded",
        graph_2: "Active Members",
        graph_3: "Number of New Research Notes",
        graph_4: "Average Number of New Notes per Week",
        graph_5: "Who Most Uploaded",
        tooltip_1: "Click to apply filter members.",
        no_active_members: "No Active Members",
        title_2: "Folder Summary",
        graph_6: "Most Active Folder",
        tooltip_2_1: "Click to apply search below.",
        tooltip_2_2: "Double-click to view research notes.",
        graph_7: "Average Number of Notes per Folder",
        graph_8: "Frequently Used Hashtags",
        no_active_hashtag: "No Active Hashtags",
        tooltip_3: "Double-click to search page.",
        graph_9: "Folder Overview",
        placeholder_input: "Search with name of members or folders.",
      },
      activities: {
        title: "Activities of Each Members",
        graph_1: "Upload Activity on Each Members",
        table_1: "Note Counts on Each Members",
      },
      folder_log: {
        msg_1: " uploaded to",
        msg_2: " folder.",
        folder: "",
        btn: "View",
        all_folders: "All Folders",
        joined_members: "Members on workspace",
        set_opened: "Open All",
        set_closed: "Close All",
        select_all_member: "Select All",
      },
      logs: {
        period: `Search period`,

        counting_title: {
          added_files: `Added file(s)`,
          average_file_cnt: `Average file count`,
          inactive_member: `Inactive member`,
        },

        options: {
          exclude_admin: `Exclude admin`,
          below_average_member: `Below-average member`,
          related_member: `Related member`,
        },
        tab: {
          upload: "Research file statistics",
          activity: "Detailed history",
        },
        graph: {
          no_date: `No data`,
          researcher: "Member",
          upload_files: "Added file(s)",
          badge: {
            admin: `Admin`,
            removed_user: `Removed`,
          },
          selector: {
            title: `Sorting option`,
            most_file: `Most file(s)`,
            least_file: `Least file(s)`,
            name_a_to_z: `Name A-Z`,
            name_z_to_a: `Name Z-A`,
          },
        },
        table: {
          clear_search: `Clear`,
          search_result_count: `Search result`,
          all_activity: `All activity`,
          selected_activity: `Selected activity`,
          option_description: `Click search to apply the filter.`,
          none_activity: `No data`,
          cell: {
            date: `Date`,
            member: `Member`,
            note: `Notes`,
            type: `Activity type`,
            content: `Details`,
            change: "Changes",
            detail_change: "Detailed changes",
          },
          options: {
            create: {
              title: `Create`,
              note: `Create note`,
              folder: `Create folder`,
              comment: `Create comment`,
            },
            modify: {
              title: `Modify`,
              note_info: `Modify note information`,
              note_options: `Modify note settings`,
              note_owner: `Change note owner`,
              manage_member: `Modify members`,
              folder_name: `Modify folder name`,
              file_name: `Rename File`,
            },
            delete: {
              title: `Delete`,
              note: `Delete note`,
              folder: `Delete folder`,
              file: `Delete file(s)`,
            },
            add_file: `Add file(s)`,
            move_file: `Move file(s)`,
            download: `Download note`,
          },
        },
      },
      folder_view: {
        title: "All Folders on workspace",
        option: {
          all: "All",
          name: "Folder name",
          member: "Member",
          admin: "Manager",
        },
      },
      folder_item: {
        owner: "Owner",
        my_auth: "My Role",
        members: "Members",
        type: "Folder Type",
        project_period: "Project Period",
        graph: "Number of Notes Uploaded",
        total_notes: "Total Number of Notes",
        shared_folder: "Shared Folder",
        private_folder: "Private Folder",
      },
    },
    service_linkage: {
      empty: "There are no folders that are interlocked.",
      title: "Github Integration",
      auto_auth: {
        text1: "With auto certification,",
        text2: "focus on your research only!",
      },
      status: "Integration Status",
      status_guest: "This is the premium user-only function.",
      status_guest_desc: "Upgrade to use full features unlimited.",
      github_box: {
        sub_text:
          "Every midnight, the commit history, comment and issue of the interlocked repository will be uploaded.",
      },
    },
    landing_commons: {
      title:
        "연구자료는 프라이빗하게 보관하고,\n기밀자료는 자유롭게 공유하세요!",
      sub_title:
        "ISO, GS 인증 등 국내외 표준을 충족하는 서비스로 \n국내 2,000곳 이상의 조직에 최고의 기술을 제공합니다.",
      note_title: "전자연구노트 구노",
      note_desc: "가장 쉽고 빠르게 연구노트를 만들어보세요",
      strongbox_title: "스트롱박스",
      strongbox_desc: "스마트한 문서 보안/공유 솔루션",
      link_home: "홈페이지",
      link_blog: "블로그",
      link_terms: "서비스 이용약관",
    },
    sign_in: {
      title: "Sign In",
      change_pw: "Change Password",
    },
    sign_up: {
      title: "Sign Up",
      check_user: "Already have a account?",
      register_email: "Sign up with Email",
      email_registered: "Already Registered. Try sign-in with Email.",
      sns_registered_1: "Already registered with ",
      sns_registered_2: ". Try sign-in again.",
    },
    change_pw: {
      title: "Change Password",
    },
    trend_chart: {
      title: "Trend by period",
      button: "File addition details",
    },
    application_trial: {
      title: "Request for Goono trial version",
      info: {
        title:
          "If you provide the information below, you can use various functions without restrictions for 14 days.",
        organization: "Organization",
        phone: "Phone number",
        seats: "Member",
        button: {
          start_trial: "Start free trial",
          view_more: "View more",
        },
        check: "Privacy Policy Agreement",
      },
      placeholder: "Organization, Company Name",
      select_box_title: "Anticipated Attendees",
      select_box_options: {
        ten: "10 people",
        twenty: "20 people",
        over_twenty: "20 or more",
      },
      function_list: {
        title: "Trial version function",
        f1: "Unlimited file additions",
        f2: "Collaborative research",
        f3: "PDF download with timestamp authentication",
        f4: "Provide research activity analysis dashboard",
      },
    },
    research_note: {
      title: "Research note",
      sub_title:
        "You can’t create research notes without a premium plan. However, we provide a sample research note for demonstration.",
      show_sample_note: "Show sample note",
    },
    onboarding: {
      title: ", Welcome!",
      sub_title:
        "Congratulations on joining the electronic note service Goono!",
      third_title:
        "Before starting the research note writing, complete your profile information first.",
      image_format: "You can only add files in PNG, JPEG, and JPG formats.",
      change_image: "change",
      table: {
        title: {
          name: "Name",
          organization: "Organization/Company",
          major: "Major/Field/Department",
          sign: "Signature (Required)",
        },
        placehoder: {
          name: "Name used within your organization.",
          organization: "organization or company.",
          major: "Major, field, or department name.",
          sign: {
            title: "Register sign",
            sub_title:
              "Register your sign to authenticate the author in the research notes. The sign is automatically applied whenever you upload a study file.",
          },
        },
        btn_next: "Next",
        helper_text: "Exceeded character limit.",
      },
      loading: "Registering profile..",
      success: "Successfully registered.",
      fail: "Fail to register profile",

      step_1: "Profile",
      step_2: "Workspace",
      third_title_step_2:
        "Please provide the name of the workspace as the final input.",
      workspace: {
        img_guide:
          "If no image is uploaded, the initial letter of the name will be used as the image.",
        form_label: "Workspace name",
        placeholder: "Enter a workspace name.",
        banner: "You can change the name in Workspace settings - Overview",
        back: "Back",
        confirm: "Start GOONO",
      },
    },
    strong_box: {
      table: {
        link: {
          header1: "Active",
          header2: "Link name",
          header3: "File name",
          header4: "Format",
          header5: "Valid date",
          header6: "Link",
          options: "Actions",
        },
        file: {
          header1: "Name",
          header2: "Created",
          header3: "Owner",
          header4: "Link",
          header5: "Path",
          options: "Actions",
        },
      },
      tooltip: {
        tutorial: "Tutorial",
        helpcenter: "Help Center",
      },
      tab: {
        link: "Links",
        file: "Files",
      },
      none_plan: {
        landing: {
          top: "There is no paid plan, so Strong Box creation is not possible.",
          top_with_sharelink:
            "There is no paid plan, so Share Link creation is not possible.",
          bottom: "However, we provide a tutorial.",
        },
        show_button: "Start Tutorial",
      },
      favorite: {
        title: "Starred Strong Box",
        empty: "No starred Strong Box",
      },
      file: {
        recent: "Recent File",
        empty: "No recent file",
      },
      list: {
        title: "All Strong Boxes",
        empty_title: "Drop files here or use the New Strong Box button",
        empty_title_highlight: "New Strong Box",
      },
      create: "New Strong Box",
      create_mobile: "New",
    },
    strong_box_write: {
      create: {
        title: "New Strong Box",
      },
      edit: {
        title: "Edit Strong Box",
      },
      info: {
        title: "Strong Box Info",
      },
      default_info: "Details",
      name_form: {
        label: "Name",
        placeholder: "Untitled Strong Box",
        helper_text: "(Required) You can change the name later.",
        helper_text_error: "글자 수를 초과하였습니다.",
      },
      create_at: "Created at",
      setting: {
        title: "Settings",
        change_owner: "Transfer ownership",
        owner_msg: (name: string) => `${name} is the owner of this Strong Box`,
      },
      member: {
        title: "Members",
      },
      remove: {
        title: "Delete Strong Box",
        alert: "Warning",
        alert_msg: "Deleting the file is permanent and can't be undone.",
        button: "Delete permanently",
        modal_msg: (name: string) => `Delete ${name}?`,
      },
      cta: {
        cancel: "Cancel",
        create: "Create",
        edit: "Save",
      },
    },
    document_box_inside: {
      nda: "NDA settings",
      folder: {
        title: "Folders",
        create: "New folder",
        none_folder: "No folder",
      },
      file: {
        title: "Files",
        upload: "New file",
        bold_01: "Drop files here",
        bold_02: "use the New File button ",
        or: "or",
        regular_01: ".",
        regular_02: "",
      },
    },
    nda_management: {
      title: "NDA settings",
      create_template: "New template",
      tabs: {
        template: "NDA Templates",
        signed: "Signed NDA",
      },
      placeholders: {
        template: "Search NDA file name",
        signed: "Search NDA template name",
      },
      template: {
        empty: "No template",
      },
      signed_nda: {
        empty: "No signed NDA",
        open: "View",
      },
    },
    nda_template_inside: {
      title: "Template Details",
      open: "Open template",
      info: {
        creator: "Owner",
        date: "Created at",
      },
      tabs: {
        signed: "Signed history",
        share_link: "Connected links",
      },
      signed_empty: "No signed history",
      link_empty: "No connected link",
    },
    personal_information_modal: {
      submit_button: (ndaId?: string) =>
        ndaId ? "Go to Policy Agree" : "Agree and Submit",
      policy_button: "View full policy",
      select_option1: "I have read and agree to the privacy policy.",
      select_option2: "I have read and agree to the cookie policy.",
      description1: "For confidentiality,",
      description2: "you must submit your email to view this file.",
      description3:
        "Review the privacy policy and submit your email to consent.",
      label_name: "Name",
      label_email: "Email",
      placeholder_name: "Your name",
      placeholder_email: "Your email address",
      title: "Submit personal information",
      sub_title:
        "For confidentiality, you must agree \n to the privacy policy to view this file. ",
    },
    policy_agreement: {
      submit_button: "Agree and Submit",
      select_option1:
        "I agree to the legal validity of electronic signatures and electronic documents.",
      select_option2:
        " I agree that the document shared for viewing after signing is the original file.",
      select_option3: "I agree to the GOONO Electronic Signature Terms of Use",
      description1:
        "GOONO adheres to the following electronic signature policies.",
      description2: `By clicking the "Agree and Submit" button, you automatically agree to the policy`,
      highlight_text: "By clicking the “Agree and Submit“ button",
      description3:
        "and acknowledge the legal validity of the electronic signature.",
      button: "View NDA Agreement",
      title: "Agree to security policy",
      sub_title:
        "For confidentiality, you must agree to the security \n policy to view this file. Please review the agreement and sign to consent.",
    },
    reply_email: {
      title: "Send Link via Email",
      submit: "Send",
      submit_hidden: "",
      title_email: "Email body",
      title_member: "Recipients",
      title_preview: "Preview",
      sub_title_email: "Type message here and preview it in real-time.",
      placeholder: "(Required) Type message here",
      add_member: "Add",
      preview_sub_title: "Share file",
      preview_description: (userName: string, fileName: string) =>
        `${userName} shared ${fileName}. \n Click the button below to view the file.`,
      open_file: "Open",
      preview_info:
        "*Please do not reply to this email. Replies will not be read.",
      location:
        "44, Eoeun-ro, Yuseong-gu, Daejeon, Republic of Korea, second floor",
    },
    document_inside: {
      title: "File Detail",
      button: {
        delete_document: "Delete",
        open_document: "Open file",
        create_document: "New link",
      },
      info: {
        creator: "Owner",
        created_at: "Created at",
      },
      tab: {
        share_link: "Custom links",
        visitor: "All Visits",
        insights: "Insights",
      },
      empty_data: {
        share_link: "No custom link",
        visitor: "No visitor",
        insights: "No page by page time-spent data",
      },
      table: {
        share_link: {
          header1: "Active",
          header2: "Name",
          header3: "Valid date",
          header4: "Link",
          header5: "Link settings",
          header6: "Delete",
        },
        visitor: {
          header1: "Name",
          header2: "Email",
          header3: "Visits",
          header4: "Downloads",
          header5: "Time spent",
        },
      },
      insights_info: {
        visitors: "Total visitors",
        time_spent: "Average time-spent",
        downloads: "Downloads",
        link: "Most visited link",
      },
      share_link_expired: "Expired",
    },
    insights_graph: {
      label: "Time spent",
      ticks: {
        min: "min",
        second: "sec",
      },
    },
    nda_custom: {
      tooltip: "서명자가 문서에 내용을 입력해야 할 위치에 놓아주세요.",
      sign_user: "서명자",
      sign: "서명",
      text: "텍스트",
      back_btn: "나가기",
      create: "공유링크 생성",
    },
  },
};
