import { createStyles, makeStyles } from "@material-ui/core";
import { theme } from "@theme";

const useSignInScreenStyles = makeStyles(() =>
  createStyles({
    SNS_form_container: {
      display: `flex`,
      justifyContent: `center`,
    },

    method_divider_container: {
      width: `100%`,
      display: `flex`,
      alignItems: `center`,
      justifyContent: `space-between`,
      color: theme.gray_5_body,
      marginTop: 40,
      marginBottom: 20,
    },
    method_divider_line: {
      width: `35%`,
      height: 1,
      backgroundColor: theme.gray_3,
    },

    footer_container: {
      marginTop: 20,
      "& button": {
        color: theme.gray_8,
      },
      "& button:last-child": {
        color: theme.gray_6,
      },
    },

    password_input: {
      "&&": {
        marginTop: 14,
      },
    },

    submit_button: {
      width: "100% !important",
      marginTop: 24,
    },
  })
);

export default useSignInScreenStyles;
