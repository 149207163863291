import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/reducers";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import {
  PersistAction,
  PersistActionKind,
  PersistState,
  persistStateMachine,
  PersistStateMachineType,
} from "src/redux/store/reducers/persist";
import moment from "moment";

const mapStateToProps = (state: RootState) => {
  return { reduxState: state.persist };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type PersistContainerProps = PropsFromRedux & {
  stateMachine: PersistStateMachineType;
};

export const handlePersistAction = (
  prevState: PersistState,
  action: PersistAction
): PersistState => {
  switch (action.kind) {
    case PersistActionKind.UPDATE_HAS_DATAROOM_SURVEY: {
      return {
        ...prevState,
        hasDataroomSurvey: action.value,
      };
    }

    case PersistActionKind.UPDATE_SESSION_ID: {
      return {
        ...prevState,
        sessionId: action.sessionId,
      };
    }

    case PersistActionKind.UPDATE_DO_NOT_NEED_ONBOARDING_USER_LIST: {
      return {
        ...prevState,
        do_not_need_onboarding_user_list: [
          ...prevState.do_not_need_onboarding_user_list,
          { email: action.user_email },
        ],
      };
    }

    case PersistActionKind.SET_LANDING_TOP_BANNER_LAST_CLOSED: {
      return {
        ...prevState,
        landding_top_banner_last_closed: moment().toISOString(),
      };
    }

    case PersistActionKind.UPDATE_UTM_LOGS: {
      /** undefined인 필드 제거 */
      const freshUTMArgs = Object.fromEntries(
        Object.entries(action.utm_args).filter(
          ([_, value]) => value !== undefined
        )
      );

      return {
        ...prevState,
        utm_args: {
          ...prevState.utm_args,
          ...freshUTMArgs,
        },
      };
    }

    case PersistActionKind.TOGGLE_LANG: {
      const freshLanguage = prevState.lang === "ko" ? "en" : "ko";
      return {
        ...prevState,
        lang: freshLanguage,
      };
    }

    case PersistActionKind.UPDATE_LAST_PASSWORD_CHANGE_INFO: {
      const newPasswordChangeInfo = action.info;

      const aleadyExistUserInfo = prevState.last_password_change_infos?.find(
        (info) => info.userId === newPasswordChangeInfo.userId
      );

      /** 이미 유저 정보가 존재하면 정보만 갈아 껴주기 */
      if (aleadyExistUserInfo && prevState.last_password_change_infos) {
        return {
          ...prevState,
          last_password_change_infos: prevState.last_password_change_infos.map(
            (info) => {
              if (info.userId === newPasswordChangeInfo.userId)
                return newPasswordChangeInfo;
              else return info;
            }
          ),
        };
        /** 없다면 새로 추가 */
      } else {
        return {
          ...prevState,
          last_password_change_infos: [
            ...(prevState.last_password_change_infos ?? []),
            newPasswordChangeInfo,
          ],
        };
      }
    }
  }
};

class PersistContainer extends ReduxStateComponent3<PersistContainerProps> {
  static defaultProps = { stateMachine: persistStateMachine };

  constructor(props: PersistContainerProps) {
    super(props);
  }

  protected onAction(
    prevState: PersistState,
    action: PersistAction
  ): PersistState {
    return handlePersistAction(prevState, action);
  }
}

export default connector(PersistContainer);
