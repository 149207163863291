import T from "@redwit-commons/utils/typecheck";
import { AccessTime, addAccessTime } from "./access_time";
import { ProjectAuthType, ProjectAuthTypeValues } from "./user_project_map";

export type SNSPlatform = "kakao" | "google" | "apple" | "test" | "email";
export const SNSPlatformList: Array<SNSPlatform> = [
  "kakao",
  "google",
  "apple",
  "test",
  "email",
];

// 사용자 구별에 필요한 필수 정보들
export type UserIdentity = {
  readonly email: string;
  readonly id: string;
  readonly apple_sub?: string;
  readonly platform: SNSPlatform;
};

export const UserIdentitySchema = T.object()
  .addField("email", T.string())
  .addField("id", T.string())
  .addField("platform", T.string().withEnum(SNSPlatformList))
  .addField("apple_sub", T.string(), false);

export enum UserProfileVersion {
  OPTIONAL_USER_SIGN = "0.0.1",
  ESSENTIAL_USER_SIGN = "1.0.0",
  NEVER_USE_LICENSE = "1.0.1",
}
// 사용자 기타 정보들
export type UserProfile = {
  deactivated?: boolean;
  name: string;
  gender: "male" | "female" | "not-to-disclose";
  school: string;
  department: string;
  marketing_term?: boolean;
  profile_cid: string;
  profile_extension: string;
  user_sign_cid?: string;
  user_sign_extension?: string;
  CipherId?: string;
  profile_version?: string;
};

export const addProfileFields = (from: T): T => {
  return from
    .addField(
      "gender",
      T.string().withEnum(["male", "female", "not-to-disclose"])
    )
    .addField("school", T.string())
    .addField("name", T.string())
    .addField("department", T.string())
    .addField("marketing_term", T.boolean(), false)
    .addField("profile_cid", T.string())
    .addField("profile_extension", T.string())
    .addField("user_sign_cid", T.string(), false)
    .addField("user_sign_extension", T.string(), false)
    .addField("CipherId", T.string(), false)
    .addField("deactivated", T.boolean(), false)
    .addField("profile_version", T.string(), false);
};

export const UserProfileSchema = addProfileFields(T.object());

// 프로파일 정보를 포함한 유저 정보 전부
export type UserCore = UserIdentity & UserProfile;
export const UserCoreSchema = addProfileFields(UserIdentitySchema.clone());

// 데이터베이스에서 가져오면 accesstime 도 적혀있음
export type UserObject = AccessTime & UserCore;
export const UserObjectSchema = addAccessTime(UserCoreSchema.clone());

export type UserObjectWithAuthType = UserObject & {
  authType: ProjectAuthType;
  pinned: string;
};

export const UserObjectWithAuthTypeSchema = UserObjectSchema.clone()
  .addField("authType", T.string().withEnum(ProjectAuthTypeValues))
  .addField("pinned", T.string());

export const validateUserCore = T.mkValidator<UserCore>(UserCoreSchema);
export const validateUserObject = T.mkValidator<UserObject>(UserObjectSchema);
export const validateUserObjectWithAuthType =
  T.mkValidator<UserObjectWithAuthType>(UserObjectWithAuthTypeSchema);

export const extractUserObject =
  T.mkObjectExtractor<UserObject>(UserObjectSchema);

export const validateUserProfile =
  T.mkValidator<UserProfile>(UserProfileSchema);
export const extractUserProfile =
  T.mkObjectExtractor<UserProfile>(UserProfileSchema);
